.fl1-desc{
  &__price{
    font-family: 'PT Sans';
    font-size: 28px;
    text-transform: uppercase;
    color: #272727;
    span.pr{
      font-size: 10px;
    }
  }
}
.template{
  display: flex;

  .temp-prew{
    width: 192px;
    margin-right: 30px;
    flex-shrink: 0;
    &__h2{
      padding-bottom: 15px;
      font-weight: 500;
      font-size: 25px;
      letter-spacing: 0.02em;
      border-bottom: 1px solid #B2B2B2;
    }
    &__p{
      padding-top: 22px;
      font-weight: 500;
      line-height: 22px;
      font-size: 13px;
    }
  }
  &-desc{
    padding-top: 11px;
    padding-right: 22px;
    &__p{
      font-weight: 500;
      line-height: 22px;
      font-size: 14px;
      &:not(:last-child){
        padding-bottom: 22px;
      }
    }
  }
  
}
.fl{
  display: flex;
  padding-top: 18px;
  .vrach-carousel{
    width: 80%;
    margin-top: 0px;
    &-col{
      width: 25%;
      padding-right: 0px;
      padding-top: 16px;
    }
    .carousel-doc{
        max-width: 80%;
    }
    .blue-blur{
        display: none;
    }
    .doc-unit{
        width: 189px;
        .doccock{
            margin: 0 auto;
            width: 189px;
            position: relative;
            padding-bottom: 4px;
        }
    }
  }
}
.until-slider{
  .slick-arrow{
    position: absolute;
    width: 14px;
    height: 46px;
    top: 50%;
    padding: 0;
    font-size: 0;
    z-index: 9;
    outline: none;
    cursor: pointer;
    opacity: .8;
    border: none;
    background: none;
    &:hover{
        opacity: 1;
    }
    &.slick-prev{
        left: -30px;
        background: url(../images/arrow-left1.svg) 0 0 no-repeat;
        background-size: 100%;
    }
    &.slick-next{
        right: -30px;
        background: url(../images/arrow-right1.svg) 0 0 no-repeat;
        background-size: 100%;
    }
  }
}