.main-section{
    max-width: 1136px;
    margin: 0 auto;
}
.main-head{
    padding-top: 22px;
    .info-block{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .phone{
            display: none;
        }
        .logo{
            img{
                height: 52px;
            }
        }
        .geo{
            display: flex;
            justify-content: space-between;
            align-items: center;
            svg{
                padding-right: 10px;
            }
            &__link{
                font-weight: 300;
                font-size: 12px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                &_adress{
                    margin-right: 30px;
                    text-decoration-line: underline;
                }           
            }
        }
        .time{
            &__p{
                text-align: right;
                font-size: 10px;
            }
            &__tel-link{
                font-weight: bold;
                line-height: normal;
                font-size: 14px;
            }
        }
    }
    .nav-bar{
        margin-top: 20px;
        display: flex;
        background-color: #328DD8;
        &-footer, &__p{
            display: none;
        }
        &__p{
            color: #328DD8;
        }
        &__link{
            display: block;
            flex-grow: 1;
            text-align: center;
            padding: 9px 0 8px;
            background: linear-gradient(180deg, #5FAAE1 0%, #4987C0 100%);
            font-size: 14px;
            letter-spacing: 0.02em;
            text-transform: uppercase;
            color: #FFFFFF;
            &:not(:last-child){
                border-right: 1px solid #328DD8;
                
            }
            &:hover{
                background: linear-gradient(180deg, #54B5FC 0%, #3E4CCB 100%);
            }
            &:active{
                background: linear-gradient(180deg, #54B5FC 0%, #3E4CCB 100%);
                box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
            }
            
        }
        
    }
}