
@media screen and (max-width: 1135px){
  .main-head .nav-bar__last{
    display: none !important;
  }
  .main-section{
    padding: 0 20px;
    box-sizing: border-box;
    & > :first-child{
      border-bottom: 2px solid #328DD8;
    }
    .banner{
      border: none;
      margin-top: -2px;
      
    background: #B5CCE7 url("../images/girl2.png") no-repeat 100% center;
    background-position-x: 100% !important;
      background-position-x: 120%;
      &__p{
        padding-top: 0;
        padding-bottom: 17px;
      }
      &__price{
        padding-bottom: 16px;
      }
      &__h2{
        font-size: 14px;
        color: white;
        padding-top: 30px;
      }
      &__h1{
        font-size: 40px;
        line-height: 61px;
        letter-spacing: 0.05em;
        color: #FFFFFF;
      }
      .fl{
        margin-top: 0;
        padding-top: 26px;
        &-doc > div{
          max-width: 148px;
        }
        &-times__ti{
          padding: 0 0 0 4px;
          box-sizing: border-box;
          width: 25px;
          height: 35px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 28px;
        }
        &-doc{
          &__act{
            font-size: 8px;
          }
          &__name{
            font-size: 14px;
          }
          &__img{
            width: 47px;

          }
        }
      }
    }
  }
  .main-head{
    padding-bottom: 17px;
    .info-block .geo{
      display: none;
    }
    .time{
      display: flex;
      align-items: center;
      .phone{
        display: none;
      }
      & img, .hamburger{
        margin-left: 27px;
      }
    }
    .hamburger{ 
      display: block;
      .line{
      width: 30px;
      height: 5px;
      background-color: #328DD8;
      display: block;
      margin: 4px auto;
      -webkit-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
        &:hover{
          cursor: pointer;
        }
      }
    }  
    .is-active{
      .line{
        &:nth-child(2){
          opacity: 0;
        }
        &:nth-child(1){
          -webkit-transform: translateY(9px) rotate(45deg);
          -ms-transform: translateY(8px) rotate(45deg);
          -o-transform: translateY(9px) rotate(45deg);
          transform: translateY(9px) rotate(45deg);
        }
        &:nth-child(3){
          -webkit-transform: translateY(-9px) rotate(-45deg);
          -ms-transform: translateY(-9px) rotate(-45deg);
          -o-transform: translateY(-9px) rotate(-45deg);
          transform: translateY(-9px) rotate(-45deg);
        }
      }
    }
    .nav-bar{
      display: none;
      flex-direction: column;
      position: absolute;
      top: 95px;
      box-sizing: border-box;
      left: 0;
      z-index: 100;
      width: 100%;
      margin: 0;
      background-color: white;
      padding: 0 20px;
      border-top: 2px solid #328DD8;
      &-copyright{
        padding: 27px 0 39px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &__p1, &__p2{
          display: block;
          font-size: 12px;
          max-width: 70%;
        }
        &__p2{
          text-align: right;
          width: 180px;
        }
      }
      &-footer, &__p{
        display: block;
        background-color: #fff;
        &__order{
          display: block;
          padding: 15px 0 14px;
          text-align: center;
          background: linear-gradient(180deg, #5FAAE1 0%, #4987C0 100%);
          font-weight: 500;
          font-size: 18px;
          color: #FFFFFF;
          border-bottom: 2px solid #62A499;
        }
        &-block{
          display: flex;
          justify-content: space-between;
          margin-top: 64px;
          padding-bottom: 10px;
          border-bottom: 1px solid #B2B2B2;
          .geo{
            display: flex;
            align-items: center;
            svg{
                padding-right: 10px;
            }
            &__link{
                font-weight: 300;
                font-size: 12px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                &_adress{
                    margin-right: 30px;
                    text-decoration-line: underline;
                }           
            }
          }
          .time{
            &__p{
                text-align: right;
                font-size: 10px;
            }
            &__tel-link{
                font-weight: normal;
                line-height: normal;
                font-size: 14px;
            }
          }
        }
      }
     
      &__p{
        font-weight: bold;
        font-size: 36px;
        padding-top: 36px;
        padding-bottom: 32px;
      }
      &__link{
        &:hover{
          background: white !important;
        }
        &:active{
          background: none;
          box-shadow: none;
        }
        &:last-child{
          border: none;
          background: none;
          background-color: white;
          margin-bottom: 50px;
        }
        padding: 14px 0;
        font-size: 30px;
        text-transform: uppercase;
        color: #328DD8;
        border-right: none !important;
        background: none;
        text-align: left;
        background-color: white;
      }

    }
  }
  .diseases > :not(:nth-child(5n)) > .dis-head, .diseases > :not(:nth-child(5n)) > .dis-down {
    border-right: none;
  }
  .diseases > :not(:nth-child(4n)) > .dis-head, .diseases > :not(:nth-child(4n)) > .dis-down {
    border-right: 1px solid #C4C4C4 !important;
  }
  .diseases{
    & > :nth-child(n + 6) {
      margin-top: 0px !important;
    } 
    & > :nth-child(n + 9) {
      display: none;
    } 
    & > :nth-child(n + 5) {
      margin-top: 69px !important;
    } 
    .dis{
      flex-basis: 25%;
      &-desc{
        padding-left: 10px;
        box-sizing: border-box;
      }
      &-img{
        &__img{
          width: 100%;
        }
      }
    }
  }
  .info-foot-links{
    display: none;
  }
  .main-foot .link-bar a{
    padding-right: 10px;
  }
  .doc-info__img{
    width: 40px !important;
  }
  .doc-info__h2{
    font-size: 14px !important; 
  }
  .link-bar .col{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &:last-child{
      justify-content: flex-start;
    }
  }
  .description .fl1-desc__h1{
    font-size: 38px;
  }
  .wrapper{
    flex-direction: column;

    .place-name{
      width: 170px;
    }
    aside{
      display: none;
    }
  }
  .description__h2_gray{
    display: block;
    width: auto;
    font-size: 14px;
  }
  .doc-list{
    flex-wrap: wrap;
    justify-content: space-between;
    .doc-unit{
      flex-basis: 30%;
      
    }
    
  }
  .doc-list > :nth-child(n + 6) {
    margin-top: 0px !important;
  }
  .doc-list > :nth-child(n + 4) {
    margin-top: 60px !important;
  }
  .main-section aside{
    display: none;
  }
  .fl .vrach-carousel{
    width: 100%;
  }
  .about-fl{
    display: block !important;
  }
  .about-block .about-fl-img{
    height: auto;
    width: 400px;
    float: left;
  }
  
  .vrach-carousel .blue-blur{
    display: none !important;
  }
  .vrach-carousel-col{
    width: 30% !important;
    padding-right: 0;
  }
  .gl{
    .slick-arrow{
      top: 300px;
    }
    .carousel-doc{
    max-width: 66%;
    }
  }
  .rec-main{
    flex-direction: column;
  }
  .service-col__a{
    font-size: 10px;
  }
  .rec-main-col3{
    display: flex;
    margin: 0;
    justify-content: space-between;
    align-items: flex-end;
    & input{
      width: 423px;
      margin-left: 10px;
    }
  }
  .service-col__h2{
    padding-bottom: 6px;
    margin-bottom: 17px;
  }
  .serv{
    width: 100%;
  }
  .service{
    flex-direction: column;
    align-items: flex-start;
  }
  .service-col{
    flex-basis: 25%;
    margin: 0;
  }
  .wrapper .doc-flex-img{
    width: 280px;
    height: auto;
    padding: 0 30px 30px 0;
    &__img{
      width: 280px;
    height: auto;
    }
  }
  .description__h2{
    font-size: 14px;
  }
  .description .fl1-desc__h1{
    font-size: 36px;
  }
  .vrach-carousel-col{
    width: 192px !important;
    margin-right: 30px;
  }
  .carousel-doc{
    width: calc(100% - 222px) !important;
  }
  .until-slider{
    width: 94%;
    margin-right:  auto;
    margin-left:  auto;
  }
}


@media screen and (max-width: 800px){
  .template{
    flex-direction: column;
    .temp-prew{
      margin: 0;
      width: 100%;
      &__p{
        font-weight: 500;
        font-size: 14px;
      }
    }
    &-desc{
      &__p{
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
  .fl{
    .vrach-carousel .slick-arrow{
      top: 300px;
    }
  }
  .gg .slick-next{
    right: 0 !important;
  }
  .vrach-carousel .doc-unit .doccock{
    margin: 0 !important;
  }
  .ff, .gg{
    &__h3{
      display: block;
    }
    .until-slider{
      position: relative;
      margin-bottom: 0;
      padding-bottom: 18px;
      border-bottom: 1px solid #B2B2B2;
    }
    .slick-arrow{
      z-index: 1000;
      height: 40px;
      width: 40px;
      top: auto;
      bottom: -51px;
      &.slick-prev{
        left: auto !important;
        right: 50px;
        background: url("../images/prev.png") center center no-repeat transparent;
        background-size: 100% !important;
        &:hover{
          background-size: 100% !important;
          background: url("../images/prev.png") center center no-repeat transparent;
        }
      }
      &.slick-next{
        background-size: 100% !important;
        background: url("../images/next-next.svg") center center no-repeat transparent;
        &:hover{
          background-size: 100% !important;
          background: url("../images/next.png") center center no-repeat transparent;
        }
      }
      &.slick-disabled {
        display: block !important;
      }
    }
    
  }
  
  
  .percent{
    flex-direction: column;
    .temp-prew{
      display: flex;
      align-items: flex-start;
      margin-bottom: 20px;
      &__h2{
        width: auto;
        font-size: 18px;
      }
      .more{
        margin: 0;
        flex-basis: 110px;
      }
    }
  }
}

@media screen and (max-width: 770px){
  .about-block .about-fl-img{
    padding-right: 0 !important;
    width: 100%;
    padding-bottom: 20px;
  } 
  .dis{
    flex-basis: 33% !important;
  }
  .diseases{ 
    & > :nth-child(n + 7) {
      display: none;
    } 
    & > :nth-child(n + 5) {
      margin-top: 0px !important;
    } 
    
  }
  .doc-list .doc-unit{
    overflow: visible;
    &:nth-child(n + 7){
      display: none;
    }
  }
  
  .doc-list{
    padding: 28px 0 45px;
    flex-wrap: wrap;
  }
  .description .fl1-desc__h1{
    font-size: 24px;
  }
  .diseases > :not(:nth-child(5n)) > .dis-head, .diseases > :not(:nth-child(5n)) > .dis-down {
    border-right: 1px solid #C4C4C4 !important;
  }
  .diseases > :nth-child(3n) > .dis-head, .diseases > :nth-child(3n) > .dis-down {
    border-right: none !important;
  }
  .diseases > :nth-child(n + 4){
    margin-top: 60px !important;
  }
}


@media screen and (max-width: 650px){
.fl .vrach-carousel .doc-unit .doccock{
  margin: 0 auto !important;
}
}


@media screen and (max-width: 610px){
  .doc-list .doc-unit:nth-child(n + 3){
    margin-top: 32px !important;
  }
}
@media screen and (max-width: 600px){

  .banner__record{
    width: 150px !important;
  }
  .main-head .info-block{
    .logo{
        img{
            height: 36px;
        }
    }
  }
  .main-head .nav-bar{
    padding: 0 11px;
    top: 79px
  }
  .main-head .nav-bar__p{
    font-size: 32px;
    padding: 22px 0 20px !important;
  }
  .main-head .nav-bar__link{
    font-size: 24px;
  }
  .pricelist-block{
    padding-right: 11px !important;
  }
  .description .fl1-desc{
    align-items: flex-start;
  }
  .description .fl1-desc__back{
    font-size: 14px !important;
    width: 130px !important;
    margin-top: 8px;
    svg{
      width: 12px !important;
      height: 12px !important;
    }
  }
  .pricelist-block .place .price-global .item-price__name{
    flex-basis: 70% !important;
  }
  .pricelist-block .place .price-global{
    flex-basis: auto !important;
  }
  .item-price__price{
    flex-basis: 1;
  }
  .place{
    flex-direction: column;
    &-name{
      padding-left: 11px !important;
    }
  }
  .pricelist-block .place-name__h2{
    font-size: 25px !important;
  }
  .pricelist-block .place .price-global__h3{
    padding-top: 15px;
    font-size: 22px !important; 
  }
  .link-bar .col_f{
    margin-bottom: 10px !important;
  }
  .col{
    flex-basis: 25% !important;
    box-sizing: border-box;
    &__p{
      padding-left: 0 !important;
    }
    &__a{
      padding-right: 0 !important;
      padding-left: 0 !important;
      &:not(:last-child){
        padding-right: 10px !important;
      }
    }
  }
  .diseases{
    padding-top: 10px;
  }
  .rec-main{
    align-items: center;
  }
  .rec-main-col3{
    flex-direction: column;
    align-items: center;
  }
  .numb-foot{
    .time__tel-link{
      font-size: 10px;
    }
  }
  .main-section{
    padding: 0 10px;
  }
  .banner{
    width: 100%;
    margin-left: -10px;
    background: #B5CCE7 url("../images/girl2.png") no-repeat 100% center;
    background-position-x: 100% !important;
    &__h1{
      font-size: 26px !important;
    }
  }
  .doc-block{
    flex-direction: column;
  }
  .doc-card-p{
    padding: 0 !important;
  }
  .doc-card{
    padding-bottom: 20px;
  }
  .link-bar{
    flex-wrap: wrap;
    border-bottom: 1px solid #E5E5E5;
    padding-bottom: 5px !important; 
    .col{
      flex-basis: 50%;
      border-right: none !important;
      br {content: ""}
      br:after {content: "\00a0"}
      &_f{
        flex-basis: 100% !important;
        border-bottom: 1px solid #E5E5E5;
        padding-bottom: 10px;
        margin-bottom: 30px;
      }
      &__a, &__p{
        padding: 0;
      }
      &__p{
        padding-bottom: 6px;
      }
      &__a{
        padding-bottom: 20px;
      }
    }
  }
  .main-head{
    .time{
      &__tel-link, &__p{
        display: none;
      }
      .phone{
        display: block;
      }
    }
  }
  .main-foot{
    .info-foot{
      flex-direction: row-reverse;
      .geo-foot{
        flex-direction: column;
        align-items: flex-start;
        &__link{
          &_adress{
          padding-bottom: 5px;
            svg{
              padding-left: 1.5px;
              padding-right: 12px; 
            }
          }
          margin: 0;
        }
      }
      .numb-foot{
        text-align: left;
        .time__tel-link1{
          padding-bottom: 5px;
        }
        a{
          display: block;
        }
      }
    }
    
    .copyright{
      padding: 25px 0 27px;
    }
  }
  .banner__laz{
    font-size: 10px !important;
  }

  .wrapper .doc-flex-img{
    width: 210px;
    &__img{
      width: 100%;
    }
  }
  .wrapper .doc-flex .merit-block__h3{
    font-size: 14px;
  }
  .wrapper .doc-flex .merit-block__p{
    font-size: 12px;
  }
  .wrapper .doc-flex .merit-block{
    padding-right: 0px !important;
    padding-bottom: 50px;
  }
  .until-slider{
    width: calc(100% + 4px);
  }
  .until-slider .until-desc{
    padding: 10px 15px 0;
    height: auto;
  }
  .banner{
    background: #B5CCE7 url("../images/girl2.png") no-repeat 100% center;
    background-position-x: right;
    &__h1{
      font-size: 26px !important;
      line-height: 39px !important;
    }
    &__record{
      font-size: 14px;
      vertical-align: middle;
    }
    .fl{
      flex-direction: column-reverse;
    &-doc{
      &__img{
        margin-left: 0 !important;
        margin-bottom: 10px;
      }
    }
    }
  }
  
  .rec-main{
    .lf{
      flex-direction: column;
      .rec-main-col1{
        margin: 0;
      }
      .rec-main-col1{
        margin-bottom: 40px;
      }
      .rec-main-col2{
        margin-left: 0;
      }
      &_col{
        input{
          margin-left: 0;
          width: 262px;
        }
      }
      
    }
    .rec-main-col3__a{
      width: 262px;
    }
  }


  .dis{
    flex-basis: 50% !important;
  }
  .diseases{ 
    & > :nth-child(n + 7) {
      display: none;
    } 
    & > :nth-child(n + 5) {
      margin-top: 0px !important;
    } 
    
  }
  .diseases > :not(:nth-child(5n)) > .dis-head, .diseases > :not(:nth-child(5n)) > .dis-down {
    border-right: none !important;
  }
  .diseases > :nth-child(2n + 1) > .dis-head, .diseases > :nth-child(2n + 1) > .dis-down {
    border-right: 1px solid #C4C4C4 !important;
  }
  .diseases > :nth-child(n + 4){
    margin-top: 0px !important;
  }
  .diseases > :nth-child(n + 3){
    margin-top: 60px !important;
  }
  .vrach-carousel-col{
    width: 40% !important;
    margin-right: 30px;
  }
  .gl .carousel-doc, .fl .carousel-doc{
    max-width: 192px;
  }
  .rec-head{
    align-items: flex-start;
    padding: 0 0 15px;
    &__h2{

    }
    &__when{

    }
  }
}

@media screen and (max-width: 390px){
  .fl .vrach-carousel .carousel-doc{
    max-width: 192px !important;
  }
  .wrapper .doc-flex-img{
    padding: 0 0 10px !important;
    width: 100% !important;
    float: none !important;
  }
  .doc-list .doc-unit .doccock{
    height: auto;
  }
  .gl .slick-arrow.slick-next, .fl .slick-arrow.slick-next{
    left: auto !important;
    right: 0px !important;
  }
  .gl .slick-arrow.slick-prev, .fl .slick-arrow.slick-prev{
    left: auto !important;
    right: 50px !important;
  }
  .doc-list .doc-unit .more{
    position: static;
  }
  .doc-list .doc-unit__desc{
    padding-bottom: 0;
  }
  .doc-list{
    justify-content: space-around !important;
  }
  .doc-list .doc-unit:nth-child(n + 3){
    margin-top: 0 !important;
  }
  .doc-list .doc-unit:nth-child(n){
    margin-top: 20px !important;
  }
  .main-foot .link-bar {
    justify-content: space-between;
    .col{
      flex-basis: 20% !important;
      &_f{
        flex-basis: 100% !important;
      }
      &__a{
        font-size: 10px;
      }
    }
  }
  .vrach-carousel{
    .carousel-doc{
        width: auto !important;
    }
}
.vrach-carousel-col{
    margin-right: 5px !important;
}
.gl .slick-arrow, .fl .slick-arrow{
    top: 388px !important;
    &.slick-next{
        left: 100px;
    }
    &.slick-prev{
        left: 40px;
    }
}
  .main-foot .link-bar .col__a{
    padding-bottom: 0 !important;
    padding-right: 0 !important;
  }
  .dis{
    flex-basis: 100% !important;
    &-desc{
      width: auto !important;
    }
  }
  .fl-doc{
    &__act, &__name{
      color:white !important;
    }
  }
  .diseases{ 
    
    & > :nth-child(n + 7) {
      display: none;
    } 
    & > :nth-child(n + 5) {
      margin-top: 0px !important;
    } 
    
  }
  .diseases > :not(:nth-child(5n)) > .dis-head, .diseases > :not(:nth-child(5n)) > .dis-down {
    border-right: none !important;
  }
  .diseases > :nth-child(2n + 1) > .dis-head, .diseases > :nth-child(2n + 1) > .dis-down {
    border-right: none;
  }
  .diseases > :nth-child(n + 4){
    margin-top: 0px !important;
  }
  .diseases > :nth-child(n + 2){
    margin-top: 20px !important;
  }
}
@media screen and (max-width: 350px){
  .fl .vrach-carousel .slick-arrow{
    top: auto !important;
    bottom: -30px !important;
  }

  .fl1-desc__price{
    display: block;
    width: 130px;
  }
  .main-head .nav-bar__link{
    padding: 8px 0;
    font-size: 18px;
  }
}