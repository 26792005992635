/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before,
.slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}
.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  outline: none;
  display: none;
}
[dir="rtl"] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}
@keyframes upUp {
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translate(0, -4px);
  }
}
* {
  padding: 0;
  margin: 0;
}
.hamburger {
  display: none;
}
.lf {
  display: flex;
}
.lf_col {
  flex-direction: column;
}
a,
p,
h1,
h2,
h3,
h4 {
  text-decoration: none;
  color: black;
  font-family: 'Roboto', sans-serif;
}
.main-nav {
  padding-bottom: 12px;
  padding-top: 15px;
}
.main-nav-p {
  font-family: 'Roboto';
  font-size: 10px;
  letter-spacing: 0.02em;
}
.main-nav-p__a {
  text-decoration: underline;
  margin-right: 2px;
}
.description {
  padding-bottom: 23px;
  border-bottom: 2px solid #328DD8;
}
.description .fl1-desc {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.description .fl1-desc__back {
  font-family: 'PT Sans';
  font-size: 28px;
  color: #C4C4C4;
  display: flex;
  width: 179px;
  justify-content: space-between;
  align-items: center;
}
.description .fl1-desc__h1 {
  font-family: 'PT Sans';
  font-size: 55px;
  letter-spacing: 0.01em;
  color: #181818;
}
.description .fl1-desc__h1 span {
  font-weight: 900;
}
.description__h2 {
  font-family: 'PT Sans';
  font-size: 18px;
  letter-spacing: 0.06em;
}
.description__h2_gray {
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 18px;
  letter-spacing: 0.09em;
  color: #C4C4C4;
  text-transform: uppercase;
}
aside {
  flex-basis: 225px;
}
aside .comment-block {
  width: 192px;
  margin: 0 auto 50px;
}
aside .comment-block__h2 {
  font-family: 'Roboto';
  padding: 16px 0 14px;
  border-bottom: 1px solid #B2B2B2;
  font-weight: 500;
  font-size: 25px;
  letter-spacing: 0.02em;
}
aside .comment-block__p1 {
  padding: 19px 0 14px;
  font-weight: 500;
  line-height: 15px;
  font-size: 12px;
  color: #328DD8;
}
aside .comment-block__p2 {
  font-family: 'PT Sans';
  line-height: 16px;
  font-size: 12px;
  padding-bottom: 2px;
}
aside .comment-block__data {
  font-family: 'PT Sans';
  font-weight: bold;
  line-height: 14px;
  font-size: 10px;
  padding-bottom: 13px;
}
aside .sale-block {
  position: relative;
  width: 100%;
  background: #B5CCE7;
  padding: 33px 26px 36px 17px;
  box-sizing: border-box;
}
aside .sale-block__p2 {
  display: none;
  font-family: 'PT Sans';
  font-weight: bold;
  font-size: 10px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  padding: 23px 0 18px;
}
aside .sale-block .fl-times {
  display: none;
}
aside .sale-block .fl-times__ti {
  width: 25px;
  height: 35px;
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  background: linear-gradient(180deg, #FFFEFE 0%, #EBEBEB 100%);
  font-family: 'PT Sans';
  font-weight: bold;
  font-size: 28px;
  letter-spacing: 0.09em;
  color: #272727;
}
aside .sale-block .fl-times__ti:not(:last-child) {
  margin-right: 3px;
}
aside .sale-block .fl-times__ti p {
  display: block;
  margin: 0 auto;
}
aside .sale-block__h2 {
  font-weight: 500;
  line-height: 29px;
  font-size: 26px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #FFFFFF;
  padding-bottom: 13px;
}
aside .sale-block__p1 {
  padding-bottom: 12px;
  font-weight: 500;
  line-height: 24px;
  font-size: 20px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}
aside .sale-block__span {
  font-family: 'PT Sans';
  font-size: 18px;
  letter-spacing: 0.11em;
  position: absolute;
  top: 218px;
  left: 93px;
}
aside .sale-block__img {
  margin-left: 11px;
}
aside .sale-block__arr {
  position: absolute;
  top: 159px;
  left: 162px;
}
.more {
  font-family: 'PT Sans';
  font-weight: bold;
  font-size: 10px;
  line-height: normal;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  display: flex;
  justify-content: space-between;
  vertical-align: middle;
  align-items: center;
  padding: 6px 6px;
  background: #328DD8;
  border-radius: 20px;
  margin-top: 10px;
  width: 89px;
}
.more:hover {
  animation: upUp 0.2s ease-in-out;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  transform: translateY(-4px);
}
.more:active {
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.more p,
.more img {
  display: block;
}
.more p {
  font-family: 'PT Sans';
  font-weight: bold;
  font-size: 10px;
  line-height: 10px;
  vertical-align: middle;
  letter-spacing: 0.02em;
  color: #FFFFFF;
}
.more_v {
  width: 120px;
}
.vrach-carousel {
  margin-top: 49px;
  padding-bottom: 34px;
  position: relative;
  display: flex;
}
.vrach-carousel .carousel-doc {
  max-width: 80%;
}
.vrach-carousel .blue-blur {
  content: '';
  width: 207px;
  height: 412px;
  background: linear-gradient(270deg, #FFFFFF 41.44%, rgba(255, 255, 255, 0) 100%);
  position: absolute;
  right: 0;
  z-index: 10;
}
.vrach-carousel .doc-unit {
  width: 189px;
}
.vrach-carousel .doc-unit .doccock {
  margin: 0 auto;
  width: 189px;
  position: relative;
}
.vrach-carousel .doc-unit-img {
  width: 100%;
  height: 193px;
}
.vrach-carousel .doc-unit-img__img {
  object-fit: cover;
  border: 1px solid #C4C4C4;
}
.vrach-carousel .doc-unit__pos {
  padding: 14px 0 8px;
  font-family: 'PT Sans';
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #272727;
}
.vrach-carousel .doc-unit__name {
  padding-bottom: 4px;
  font-weight: 500;
  font-size: 18px;
  color: #328DD8;
}
.vrach-carousel .doc-unit__desc {
  font-family: 'PT Sans';
  line-height: 16px;
  font-size: 12px;
}
.vrach-carousel .doc-unit .more {
  position: static;
  margin-top: 11px;
}
.vrach-carousel-col {
  width: 20%;
  padding-right: 30px;
  box-sizing: border-box;
}
.vrach-carousel-col__h2 {
  padding-bottom: 16px;
  font-weight: 500;
  font-size: 25px;
  letter-spacing: 0.07em;
  border-bottom: 1px solid #B2B2B2;
}
.vrach-carousel-col__p {
  padding-top: 19px;
  font-family: 'PT Sans';
  line-height: 16px;
  font-size: 12px;
}
.vrach-carousel .slick-arrow {
  position: absolute;
  width: 44px;
  height: 44px;
  top: 280px;
  padding: 0;
  font-size: 0;
  z-index: 9;
  outline: none;
  cursor: pointer;
  opacity: 0.8;
  border: none;
  background: none;
}
.vrach-carousel .slick-arrow:hover {
  opacity: 1;
}
.vrach-carousel .slick-arrow.slick-prev {
  left: -130px;
  background: url(../images/arrow-left.svg) 0 0 no-repeat;
  background-size: 100%;
}
.vrach-carousel .slick-arrow.slick-next {
  left: -73px;
  background: url(../images/arrow-right.svg) 0 0 no-repeat;
  background-size: 100%;
}
.slick-disabled {
  display: none !important;
}
.until-slider {
  margin-bottom: 58px;
}
.until-slider .until-head {
  margin-bottom: 11px;
  border-right: 1px solid #C4C4C4;
  display: flex;
}
.until-slider .until-head-p {
  flex-basis: 50%;
}
.until-slider .until-head-p__p {
  padding-bottom: 3px;
  padding-left: 16px;
  font-family: 'PT Sans';
  font-size: 16px;
  letter-spacing: 0.04em;
}
.until-slider .until-img {
  display: flex;
}
.until-slider .until-img1 {
  flex-basis: 50%;
  overflow: hidden;
}
.until-slider .until-img1__img {
  width: 100%;
  object-fit: cover;
}
.until-slider .until-desc {
  border-right: 1px solid #C4C4C4;
  height: 243px;
  padding: 17px 30px 0px 16px;
  box-sizing: border-box;
}
.until-slider .until-desc__p {
  font-family: 'PT Sans';
  font-size: 12px;
}
.until-slider .until-desc__p:not(:last-child) {
  padding-bottom: 15px;
}
.main-section {
  max-width: 1136px;
  margin: 0 auto;
}
.main-head {
  padding-top: 22px;
}
.main-head .info-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.main-head .info-block .phone {
  display: none;
}
.main-head .info-block .logo img {
  height: 52px;
}
.main-head .info-block .geo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.main-head .info-block .geo svg {
  padding-right: 10px;
}
.main-head .info-block .geo__link {
  font-weight: 300;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.main-head .info-block .geo__link_adress {
  margin-right: 30px;
  text-decoration-line: underline;
}
.main-head .info-block .time__p {
  text-align: right;
  font-size: 10px;
}
.main-head .info-block .time__tel-link {
  font-weight: bold;
  line-height: normal;
  font-size: 14px;
}
.main-head .nav-bar {
  margin-top: 20px;
  display: flex;
  background-color: #328DD8;
}
.main-head .nav-bar-footer,
.main-head .nav-bar__p {
  display: none;
}
.main-head .nav-bar__p {
  color: #328DD8;
}
.main-head .nav-bar__link {
  display: block;
  flex-grow: 1;
  text-align: center;
  padding: 9px 0 8px;
  background: linear-gradient(180deg, #5FAAE1 0%, #4987C0 100%);
  font-size: 14px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #FFFFFF;
}
.main-head .nav-bar__link:not(:last-child) {
  border-right: 1px solid #328DD8;
}
.main-head .nav-bar__link:hover {
  background: linear-gradient(180deg, #54B5FC 0%, #3E4CCB 100%);
}
.main-head .nav-bar__link:active {
  background: linear-gradient(180deg, #54B5FC 0%, #3E4CCB 100%);
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.index__h1 {
  text-align: center;
  padding: 20px 0 20px;
  text-transform: uppercase;
  font-size: 20px;
}
.doc-block {
  position: relative;
}
.banner {
  max-width: 100%;
  background: #B5CCE7 url("../images/girl.png") no-repeat 100% center;
  background-size: auto 100%;
  color: white;
  padding-left: 21px;
}
.banner a {
  color: white;
}
.banner__h2 {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  font-size: 18px;
  padding-top: 22px;
}
.banner__h1 {
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: bold;
  line-height: 75px;
  font-size: 55px;
  letter-spacing: 0.05em;
}
.banner__laz {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  font-size: 18px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #272727;
  padding-top: 6px;
}
.banner__price {
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 20px;
  padding-bottom: 20px;
}
.banner__price-span {
  font-size: 13px;
}
.banner__record {
  display: flex;
  width: 192px;
  justify-content: space-between;
  align-items: center;
  background: rgba(75, 195, 233, 0.8);
  border: 3px solid #FFFFFF;
  box-sizing: border-box;
  box-shadow: 5px 10px 25px rgba(57, 162, 195, 0.6);
  border-radius: 20px;
  padding: 5px 7px 5px 15px;
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 20px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}
.banner__record:hover {
  cursor: pointer;
  animation: upUp 0.3s linear;
  transform: translateY(-4px);
}
.banner .fl {
  display: flex;
  margin-top: 28px;
}
.banner .fl-times {
  display: flex;
}
.banner .fl-times__ti {
  padding: 8px 6px;
  background-color: white;
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  font-size: 36px;
  letter-spacing: 0.09em;
  text-transform: uppercase;
  color: #272727;
}
.banner .fl-times__ti:not(:last-child) {
  margin-right: 4px;
}
.banner .fl-doc {
  display: flex;
  align-items: center;
}
.banner .fl-doc__img {
  margin-left: 39px;
  margin-right: 5px;
  display: block;
  width: 56px;
  border-radius: 56px;
  border: 2px solid #E8E8E8;
}
.banner .fl-doc__act {
  font-family: 'PT Sans';
  font-weight: bold;
  font-size: 10px;
  letter-spacing: 0.04em;
  color: #272727;
}
.banner .fl-doc__name {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  font-size: 18px;
  letter-spacing: 0.01em;
  padding-top: 2px;
  color: #181818;
}
.banner__p {
  font-family: 'PT Sans';
  padding-top: 7px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 10px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #272727;
  padding-bottom: 18px;
}
.diseases {
  display: flex;
  flex-wrap: wrap;
  padding: 73px 0 29px;
  box-sizing: border-box;
  max-width: 1136px;
}
.diseases > :not(:nth-child(5n)) > .dis-head,
.diseases > :not(:nth-child(5n)) > .dis-down {
  border-right: 1px solid #C4C4C4;
}
.diseases > :nth-child(n + 6) {
  margin-top: 39px;
}
.diseases .dis {
  flex-basis: 20%;
}
.diseases .dis-down {
  padding-bottom: 10px;
}
.diseases .dis:hover {
  background-color: #D6ECFF;
}
.diseases .dis-head {
  display: flex;
  align-items: flex-end;
  height: 67px;
  box-sizing: border-box;
  padding: 0 0 18px;
}
.diseases .dis-head a {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
}
.diseases .dis-img__img {
  object-fit: cover;
}
.diseases .dis-desc {
  width: 181px;
  margin: 0 auto;
}
.diseases .dis-desc__desc {
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 12px;
  color: black;
  padding: 16px 0 11px;
}
.diseases .dis-desc__more {
  width: 141px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #328DD8;
  box-sizing: border-box;
  border-radius: 50px;
  padding: 6px 31px;
  font-family: 'Roboto';
  font-size: 12px;
}
.diseases .dis-desc__more:hover {
  animation: upUp 0.4s ease-in-out;
  transform: translate(0, -4px);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.diseases .dis-desc__more:active {
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.doctors {
  padding-bottom: 44px;
}
.doctors__h1 {
  padding-bottom: 23px;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.03em;
}
.doctors .doc-block {
  display: flex;
  justify-content: space-between;
}
.doctors .doc-block .doc-card {
  flex-basis: 25%;
}
.doctors .doc-block .doc-card-p {
  padding-right: 30px;
}
.doctors .doc-block .doc-card__p {
  font-family: 'PT Sans';
  font-size: 12px;
  letter-spacing: 0.04em;
  color: #272727;
}
.doctors .doc-block .doc-card .doc-info {
  margin-bottom: 8px;
}
.doctors .doc-block .doc-card .doc-info__img {
  float: left;
  width: 82px;
  border-radius: 45px;
  border: 4px solid #E8E8E8;
  margin-right: 11px;
}
.doctors .doc-block .doc-card .doc-info:after {
  content: " ";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
  font-size: 0;
}
.doctors .doc-block .doc-card .doc-info__h2 {
  font-family: 'Roboto';
  font-size: 18px;
  color: #328DD8;
  padding-bottom: 9px;
}
.doctors .doc-block .doc-card .doc-info__p {
  font-family: 'PT Sans';
  font-weight: bold;
  font-size: 9px;
  letter-spacing: 0.04em;
  color: #272727;
}
.fl1-desc__price {
  font-family: 'PT Sans';
  font-size: 28px;
  text-transform: uppercase;
  color: #272727;
}
.fl1-desc__price span.pr {
  font-size: 10px;
}
.template {
  display: flex;
}
.template .temp-prew {
  width: 192px;
  margin-right: 30px;
  flex-shrink: 0;
}
.template .temp-prew__h2 {
  padding-bottom: 15px;
  font-weight: 500;
  font-size: 25px;
  letter-spacing: 0.02em;
  border-bottom: 1px solid #B2B2B2;
}
.template .temp-prew__p {
  padding-top: 22px;
  font-weight: 500;
  line-height: 22px;
  font-size: 13px;
}
.template-desc {
  padding-top: 11px;
  padding-right: 22px;
}
.template-desc__p {
  font-weight: 500;
  line-height: 22px;
  font-size: 14px;
}
.template-desc__p:not(:last-child) {
  padding-bottom: 22px;
}
.fl {
  display: flex;
  padding-top: 18px;
}
.fl .vrach-carousel {
  width: 80%;
  margin-top: 0px;
}
.fl .vrach-carousel-col {
  width: 25%;
  padding-right: 0px;
  padding-top: 16px;
}
.fl .vrach-carousel .carousel-doc {
  max-width: 80%;
}
.fl .vrach-carousel .blue-blur {
  display: none;
}
.fl .vrach-carousel .doc-unit {
  width: 189px;
}
.fl .vrach-carousel .doc-unit .doccock {
  margin: 0 auto;
  width: 189px;
  position: relative;
  padding-bottom: 4px;
}
.until-slider .slick-arrow {
  position: absolute;
  width: 14px;
  height: 46px;
  top: 50%;
  padding: 0;
  font-size: 0;
  z-index: 9;
  outline: none;
  cursor: pointer;
  opacity: 0.8;
  border: none;
  background: none;
}
.until-slider .slick-arrow:hover {
  opacity: 1;
}
.until-slider .slick-arrow.slick-prev {
  left: -30px;
  background: url(../images/arrow-left1.svg) 0 0 no-repeat;
  background-size: 100%;
}
.until-slider .slick-arrow.slick-next {
  right: -30px;
  background: url(../images/arrow-right1.svg) 0 0 no-repeat;
  background-size: 100%;
}
.wrapper {
  padding-top: 36px;
  display: flex;
  justify-content: space-between;
}
.wrapper .doc-flex-img {
  width: 420px;
  height: 492px;
  float: left;
  padding: 0 47px 57px 18px;
}
.wrapper .doc-flex-img__img {
  object-fit: cover;
}
.wrapper .doc-flex:after {
  content: " ";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
  font-size: 0;
}
.wrapper .doc-flex .merit-block {
  padding-right: 23px;
}
.wrapper .doc-flex .merit-block__h3 {
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  font-size: 18px;
  letter-spacing: 0.02em;
  padding-bottom: 24px;
}
.wrapper .doc-flex .merit-block__p {
  font-weight: 500;
  line-height: 17px;
  font-size: 12px;
}
.wrapper .doc-flex .merit-block__p:not(:last-child) {
  padding-bottom: 17px;
}
.ff__h3 {
  display: none;
  padding-top: 23px;
  font-weight: 500;
  font-size: 16px;
  line-height: normal;
  letter-spacing: 0.02em;
  padding-bottom: 40px;
}
.ff .slick-arrow {
  position: absolute;
  height: 100%;
  width: 20%;
  padding: 0;
  top: 0px;
  font-size: 0;
  z-index: 90;
  outline: none;
  cursor: pointer;
  border: none;
  background: none;
}
.ff .slick-arrow.slick-prev {
  left: 0px;
  background: url(../images/prev.png) center 120px no-repeat;
}
.ff .slick-arrow.slick-prev:hover {
  background: url(../images/prev.png) center 120px no-repeat, linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 100%);
}
.ff .slick-arrow.slick-next {
  right: 0px;
  background: url(../images/next.png) center 120px no-repeat;
}
.ff .slick-arrow.slick-next:hover {
  background: url(../images/next.png) center 120px no-repeat, linear-gradient(270deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0) 100%);
}
.ff .slick-arrow .slick-disabled {
  display: none !important;
}
.percent {
  display: flex;
  padding-right: 11px;
  width: 100%;
  margin-bottom: 72px;
  justify-content: space-between;
}
.percent .temp-prew {
  justify-content: space-between;
}
.percent .temp-prew__h2 {
  width: 203px;
  font-weight: 500;
  line-height: normal;
  font-size: 25px;
  font-style: normal;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}
.percent .more {
  padding: 3px 4px 2px 6px;
  align-items: center;
}
.percent .more img {
  width: 14px;
  height: 14px;
}
.percent .cont-calen {
  overflow-y: auto;
}
.percent .calen {
  width: 655px;
  border-collapse: collapse;
  border: 2px solid white;
}
.percent .calen th {
  text-align: left;
  padding: 4px 0px 7px 6px;
  width: 92px;
  font-family: 'PT Sans';
  font-weight: bold;
  font-size: 10px;
  letter-spacing: 0.02em;
}
.percent .calen .calen_active {
  background-color: #328DD8;
}
.percent .calen .calen_active .calen__p1,
.percent .calen .calen_active .calen__p2 {
  color: white;
}
.percent .calen td,
.percent .calen th {
  border: 1px solid #C4C4C4;
}
.percent .calen td {
  vertical-align: top;
}
.percent .calen td:hover {
  background-color: #B5CCE7;
  cursor: pointer;
}
.percent .calen td:hover .calen__p1 {
  color: white;
}
.percent .calen td:hover .calen__p2 {
  color: #181818;
}
.percent .calen__p1 {
  font-family: 'PT Sans';
  font-weight: bold;
  font-size: 26px;
  letter-spacing: 0.02em;
  color: #C4C4C4;
  padding: 3px 0 1px 3px;
}
.percent .calen__p2 {
  font-family: 'PT Sans';
  font-size: 10px;
  color: #C4C4C4;
  padding-left: 4px;
  padding-bottom: 5px;
}
.percent .calen_sv .calen__p1 {
  color: #181818;
}
.doc-list {
  padding: 28px 11px 47px 20px;
  display: flex;
  flex-wrap: wrap;
}
.doc-list > :nth-child(n + 6) {
  margin-top: 49px;
}
.doc-list .doc-unit {
  flex-basis: 20%;
  overflow: hidden;
}
.doc-list .doc-unit .doccock {
  width: 189px;
  margin: 0 auto;
  height: 386px;
  position: relative;
  padding-bottom: 4px;
}
.doc-list .doc-unit-img {
  width: 100%;
  height: 193px;
  border: 1px solid #C4C4C4;
}
.doc-list .doc-unit-img__img {
  object-fit: cover;
}
.doc-list .doc-unit__pos {
  padding: 14px 0 8px;
  font-family: 'PT Sans';
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #272727;
}
.doc-list .doc-unit__name {
  padding-bottom: 4px;
  font-weight: 500;
  font-size: 18px;
  color: #328DD8;
}
.doc-list .doc-unit__desc {
  padding-bottom: 16px;
  font-family: 'PT Sans';
  line-height: 16px;
  font-size: 12px;
}
.doc-list .doc-unit .more {
  position: absolute;
  bottom: 4px;
}
.about-block {
  padding-top: 27px;
}
.about-block .about-fl {
  display: flex;
}
.about-block .about-fl-img {
  width: 649px;
  height: 451px;
  padding-right: 30px;
  flex-shrink: 2;
}
.about-block .about-fl-img__img {
  object-fit: cover;
}
.about-block .about-fl-desc__h2 {
  font-family: 'PT Sans';
  line-height: 28px;
  font-size: 28px;
  letter-spacing: 0.09em;
  color: #328DD8;
  padding-bottom: 28px;
}
.about-block .about-fl-desc__h2_bold {
  font-weight: bold;
}
.about-block .about-fl-desc__p {
  line-height: 22px;
  font-size: 14px;
  padding-bottom: 22px;
}
.service-col1 {
  flex-basis: 20% !important;
  margin: 0 !important;
  padding: 0 !important;
}
.serv {
  display: flex;
}
.service {
  display: flex;
}
.service-col {
  flex-basis: 25%;
  padding-top: 10px;
  margin-left: 30px;
}
.service-col__h2 {
  padding-bottom: 16px;
  font-weight: 500;
  font-size: 25px;
  letter-spacing: 0.07em;
  border-bottom: 1px solid #B2B2B2;
}
.service-col__a {
  display: block;
  text-transform: uppercase;
  font-family: 'PT Sans';
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0.05em;
  text-decoration-line: underline;
  padding-right: 10px;
  color: #328DD8;
}
.service-col__a:not(:last-child) {
  padding-bottom: 15px;
}
.service-col__p {
  padding-top: 19px;
  font-family: 'PT Sans';
  line-height: 16px;
  font-size: 12px;
}
.rec-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 22px 23px;
  margin-bottom: 27px;
  border-bottom: 2px solid #B5CCE7;
}
.rec-head__h2 {
  font-weight: 500;
  font-size: 25px;
  letter-spacing: 0.02em;
}
.rec-head__when {
  font-family: 'PT Sans';
  font-size: 14px;
}
.rec-main {
  display: flex;
  padding-bottom: 55px;
}
.rec-main-col label {
  display: block;
  font-family: 'PT Sans';
  font-size: 14px;
  letter-spacing: 0.04em;
  padding-left: 17px;
  padding-bottom: 12px;
}
.rec-main-col1 {
  margin-left: 10px;
  height: 34px;
}
.rec-main-col2 {
  margin-left: 12px;
}
.rec-main-col2 .select_mate {
  min-width: 145px !important;
  width: 145px !important;
}
.rec-main-col3 {
  margin-left: 45px;
  margin-top: 10px;
}
.rec-main-col3 input {
  outline: none;
  border: 1px solid #328DD8;
  box-sizing: border-box;
  border-radius: 21.5px;
  width: 457px;
  font-family: 'PT Sans';
  font-size: 14px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  padding: 11px 0 10px 15px;
  margin-top: 21px;
}
.rec-main-col3__a {
  display: flex;
  width: 192px;
  justify-content: space-between;
  align-items: center;
  background-color: #328DD8;
  box-sizing: border-box;
  border-radius: 21.5px;
  padding: 10px 17px;
  font-family: 'PT Sans';
  font-size: 14px;
  margin-top: 21px;
  color: white;
  letter-spacing: 0.03em;
  text-transform: uppercase;
}
.rec-main-col3__a:hover {
  cursor: pointer;
}
.rec .icon_select_mate {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 16px;
  height: 20px;
  transition: all 275ms;
}
.rec .icon_select_mate:hover {
  cursor: pointer;
}
.rec .select_mate {
  position: relative;
  float: left;
  min-width: 258px;
  width: 258px;
  min-height: 37px;
  font-family: 'PT Sans';
  color: #000;
  font-size: 14px;
  letter-spacing: 0.02em;
  font-weight: 300;
  background-color: #fff;
  border: 2px solid #328DD8;
  border-radius: 21.5px;
  transition: all 375ms ease-in-out;
}
.rec .select_mate select {
  position: absolute;
  overflow: hidden;
  height: 0px;
  opacity: 0;
  z-index: -1;
}
.rec .cont_list_select_mate {
  position: relative;
  float: left;
  width: 100%;
}
.rec .cont_select_int {
  position: absolute;
  left: 0px;
  top: 3px;
  z-index: 999;
  overflow: hidden;
  height: 0px;
  width: 100%;
  background-color: #fff;
  padding: 0px;
  margin-bottom: 0px;
  margin-top: 0px;
  border-radius: 5px;
  transition: all 375ms ease-in-out;
}
.rec .cont_select_int li {
  position: relative;
  float: left;
  width: 258px;
  border-bottom: 1px solid #E0E0E0;
  background-color: #F5F5F5;
  list-style-type: none;
  padding: 10px 13px;
  margin: 0px;
  transition: all 275ms ease-in-out;
  display: block;
  cursor: pointer;
}
.rec .cont_select_int li:last-child {
  border-radius: 3px;
  border-bottom: 0px;
}
.rec .cont_select_int li:hover {
  background-color: #EEEEEE;
}
.rec .cont_select_int .active {
  background-color: #EEEEEE;
}
.rec .selecionado_opcion {
  padding: 9px 0px 10px 15px;
  width: 100%;
  display: block;
  margin: 0px;
  cursor: pointer;
}
.pricelist-block {
  width: 100%;
  padding-right: 23px;
  box-sizing: border-box;
}
.pricelist-block .eyes {
  padding-top: 50px;
}
.pricelist-block .eyes .price-global {
  padding-top: 13px;
}
.pricelist-block .place {
  display: flex;
  justify-content: space-between;
  padding-bottom: 38px;
}
.pricelist-block .place:not(:last-child) {
  border-bottom: 1px solid #B2B2B2;
}
.pricelist-block .place-name {
  padding-left: 22px;
}
.pricelist-block .place-name__h2 {
  font-weight: 500;
  font-size: 24px;
}
.pricelist-block .place .price-global {
  flex-basis: 636px;
}
.pricelist-block .place .price-global__h3,
.pricelist-block .place .price-global__exp,
.pricelist-block .place .price-global__h4 {
  margin-left: 11px;
}
.pricelist-block .place .price-global__h3 {
  font-weight: 500;
  font-size: 25px;
  letter-spacing: 0.01em;
  padding-bottom: 8px;
}
.pricelist-block .place .price-global__h3_m {
  margin-top: 47px;
}
.pricelist-block .place .price-global__exp {
  line-height: 12px;
  font-size: 10px;
  color: #328DD8;
  padding-bottom: 13px;
}
.pricelist-block .place .price-global__exp_g {
  color: #21D3BC;
  border-bottom: 1px solid #B2B2B2;
  margin-bottom: 12px;
}
.pricelist-block .place .price-global__h4 {
  font-weight: 900;
  font-size: 12px;
  letter-spacing: 0.05em;
  padding: 14px 0;
  border-bottom: 1px solid #B2B2B2;
  margin-bottom: 12px;
}
.pricelist-block .place .price-global .item-price {
  padding: 2px 13px 2px 11px;
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
}
.pricelist-block .place .price-global .item-price_p {
  margin-top: 36px;
}
.pricelist-block .place .price-global .item-price__name {
  flex-basis: 450px;
}
.pricelist-block .place .price-global .item-price:hover {
  background: #D6E3F3;
  cursor: pointer;
}
.main-foot {
  max-width: 100%;
}
.main-foot .info-foot {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 26px 0;
  border-top: 1px solid #E5E5E5;
  border-bottom: 1px solid #E5E5E5;
}
.main-foot .info-foot-links a {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 12px;
  letter-spacing: 0.03em;
  text-decoration-line: underline;
  color: #181818;
}
.main-foot .info-foot-links a:not(:last-child) {
  margin-right: 14px;
}
.main-foot .info-foot-links a:hover {
  color: #328DD8;
}
.main-foot .info-foot-links a:active {
  font-weight: 500;
  color: #328DD8;
}
.main-foot .info-foot .geo-foot {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.main-foot .info-foot .geo-foot svg {
  padding-right: 10px;
}
.main-foot .info-foot .geo-foot svg path {
  fill: #328DD8;
}
.main-foot .info-foot .geo-foot svg circle {
  stroke: #328DD8;
}
.main-foot .info-foot .geo-foot__link {
  font-weight: 300;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.main-foot .info-foot .geo-foot__link_adress {
  margin-right: 30px;
  text-decoration-line: underline;
}
.main-foot .info-foot .numb-foot {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  font-size: 14px;
  text-align: right;
  color: #000000;
}
.main-foot .link-bar {
  display: flex;
  padding-top: 25px;
  padding-bottom: 27px;
}
.main-foot .link-bar p,
.main-foot .link-bar a {
  display: block;
  padding: 12px 0 12px 20px;
}
.main-foot .link-bar .col {
  flex-basis: 20%;
}
.main-foot .link-bar .col:not(:last-child) {
  border-right: 1px solid #B2B2B2;
}
.main-foot .link-bar .col__a {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  line-height: 17px;
  font-size: 12px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: #328DD8;
}
.main-foot .link-bar .col__a:hover {
  text-decoration: underline;
}
.main-foot .link-bar .col__a:active {
  color: #1373C2;
  font-weight: 900;
  text-decoration: none;
}
.main-foot .link-bar .col__p {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  font-size: 12px;
  letter-spacing: 0.03em;
  color: #181818;
}
.main-foot .copyright {
  display: flex;
  justify-content: space-between;
  padding: 0 20px 27px;
}
.main-foot .copyright__p {
  display: block;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 9px;
}
@media screen and (max-width: 1135px) {
  .main-head .nav-bar__last {
    display: none !important;
  }
  .main-section {
    padding: 0 20px;
    box-sizing: border-box;
  }
  .main-section > :first-child {
    border-bottom: 2px solid #328DD8;
  }
  .main-section .banner {
    border: none;
    margin-top: -2px;
    background: #B5CCE7 url("../images/girl2.png") no-repeat 100% center;
    background-position-x: 100% !important;
    background-position-x: 120%;
  }
  .main-section .banner__p {
    padding-top: 0;
    padding-bottom: 17px;
  }
  .main-section .banner__price {
    padding-bottom: 16px;
  }
  .main-section .banner__h2 {
    font-size: 14px;
    color: white;
    padding-top: 30px;
  }
  .main-section .banner__h1 {
    font-size: 40px;
    line-height: 61px;
    letter-spacing: 0.05em;
    color: #FFFFFF;
  }
  .main-section .banner .fl {
    margin-top: 0;
    padding-top: 26px;
  }
  .main-section .banner .fl-doc > div {
    max-width: 148px;
  }
  .main-section .banner .fl-times__ti {
    padding: 0 0 0 4px;
    box-sizing: border-box;
    width: 25px;
    height: 35px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 28px;
  }
  .main-section .banner .fl-doc__act {
    font-size: 8px;
  }
  .main-section .banner .fl-doc__name {
    font-size: 14px;
  }
  .main-section .banner .fl-doc__img {
    width: 47px;
  }
  .main-head {
    padding-bottom: 17px;
  }
  .main-head .info-block .geo {
    display: none;
  }
  .main-head .time {
    display: flex;
    align-items: center;
  }
  .main-head .time .phone {
    display: none;
  }
  .main-head .time img,
  .main-head .time .hamburger {
    margin-left: 27px;
  }
  .main-head .hamburger {
    display: block;
  }
  .main-head .hamburger .line {
    width: 30px;
    height: 5px;
    background-color: #328DD8;
    display: block;
    margin: 4px auto;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .main-head .hamburger .line:hover {
    cursor: pointer;
  }
  .main-head .is-active .line:nth-child(2) {
    opacity: 0;
  }
  .main-head .is-active .line:nth-child(1) {
    -webkit-transform: translateY(9px) rotate(45deg);
    -ms-transform: translateY(8px) rotate(45deg);
    -o-transform: translateY(9px) rotate(45deg);
    transform: translateY(9px) rotate(45deg);
  }
  .main-head .is-active .line:nth-child(3) {
    -webkit-transform: translateY(-9px) rotate(-45deg);
    -ms-transform: translateY(-9px) rotate(-45deg);
    -o-transform: translateY(-9px) rotate(-45deg);
    transform: translateY(-9px) rotate(-45deg);
  }
  .main-head .nav-bar {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 95px;
    box-sizing: border-box;
    left: 0;
    z-index: 100;
    width: 100%;
    margin: 0;
    background-color: white;
    padding: 0 20px;
    border-top: 2px solid #328DD8;
  }
  .main-head .nav-bar-copyright {
    padding: 27px 0 39px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .main-head .nav-bar-copyright__p1,
  .main-head .nav-bar-copyright__p2 {
    display: block;
    font-size: 12px;
    max-width: 70%;
  }
  .main-head .nav-bar-copyright__p2 {
    text-align: right;
    width: 180px;
  }
  .main-head .nav-bar-footer,
  .main-head .nav-bar__p {
    display: block;
    background-color: #fff;
  }
  .main-head .nav-bar-footer__order,
  .main-head .nav-bar__p__order {
    display: block;
    padding: 15px 0 14px;
    text-align: center;
    background: linear-gradient(180deg, #5FAAE1 0%, #4987C0 100%);
    font-weight: 500;
    font-size: 18px;
    color: #FFFFFF;
    border-bottom: 2px solid #62A499;
  }
  .main-head .nav-bar-footer-block,
  .main-head .nav-bar__p-block {
    display: flex;
    justify-content: space-between;
    margin-top: 64px;
    padding-bottom: 10px;
    border-bottom: 1px solid #B2B2B2;
  }
  .main-head .nav-bar-footer-block .geo,
  .main-head .nav-bar__p-block .geo {
    display: flex;
    align-items: center;
  }
  .main-head .nav-bar-footer-block .geo svg,
  .main-head .nav-bar__p-block .geo svg {
    padding-right: 10px;
  }
  .main-head .nav-bar-footer-block .geo__link,
  .main-head .nav-bar__p-block .geo__link {
    font-weight: 300;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .main-head .nav-bar-footer-block .geo__link_adress,
  .main-head .nav-bar__p-block .geo__link_adress {
    margin-right: 30px;
    text-decoration-line: underline;
  }
  .main-head .nav-bar-footer-block .time__p,
  .main-head .nav-bar__p-block .time__p {
    text-align: right;
    font-size: 10px;
  }
  .main-head .nav-bar-footer-block .time__tel-link,
  .main-head .nav-bar__p-block .time__tel-link {
    font-weight: normal;
    line-height: normal;
    font-size: 14px;
  }
  .main-head .nav-bar__p {
    font-weight: bold;
    font-size: 36px;
    padding-top: 36px;
    padding-bottom: 32px;
  }
  .main-head .nav-bar__link {
    padding: 14px 0;
    font-size: 30px;
    text-transform: uppercase;
    color: #328DD8;
    border-right: none !important;
    background: none;
    text-align: left;
    background-color: white;
  }
  .main-head .nav-bar__link:hover {
    background: white !important;
  }
  .main-head .nav-bar__link:active {
    background: none;
    box-shadow: none;
  }
  .main-head .nav-bar__link:last-child {
    border: none;
    background: none;
    background-color: white;
    margin-bottom: 50px;
  }
  .diseases > :not(:nth-child(5n)) > .dis-head,
  .diseases > :not(:nth-child(5n)) > .dis-down {
    border-right: none;
  }
  .diseases > :not(:nth-child(4n)) > .dis-head,
  .diseases > :not(:nth-child(4n)) > .dis-down {
    border-right: 1px solid #C4C4C4 !important;
  }
  .diseases > :nth-child(n + 6) {
    margin-top: 0px !important;
  }
  .diseases > :nth-child(n + 9) {
    display: none;
  }
  .diseases > :nth-child(n + 5) {
    margin-top: 69px !important;
  }
  .diseases .dis {
    flex-basis: 25%;
  }
  .diseases .dis-desc {
    padding-left: 10px;
    box-sizing: border-box;
  }
  .diseases .dis-img__img {
    width: 100%;
  }
  .info-foot-links {
    display: none;
  }
  .main-foot .link-bar a {
    padding-right: 10px;
  }
  .doc-info__img {
    width: 40px !important;
  }
  .doc-info__h2 {
    font-size: 14px !important;
  }
  .link-bar .col {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .link-bar .col:last-child {
    justify-content: flex-start;
  }
  .description .fl1-desc__h1 {
    font-size: 38px;
  }
  .wrapper {
    flex-direction: column;
  }
  .wrapper .place-name {
    width: 170px;
  }
  .wrapper aside {
    display: none;
  }
  .description__h2_gray {
    display: block;
    width: auto;
    font-size: 14px;
  }
  .doc-list {
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .doc-list .doc-unit {
    flex-basis: 30%;
  }
  .doc-list > :nth-child(n + 6) {
    margin-top: 0px !important;
  }
  .doc-list > :nth-child(n + 4) {
    margin-top: 60px !important;
  }
  .main-section aside {
    display: none;
  }
  .fl .vrach-carousel {
    width: 100%;
  }
  .about-fl {
    display: block !important;
  }
  .about-block .about-fl-img {
    height: auto;
    width: 400px;
    float: left;
  }
  .vrach-carousel .blue-blur {
    display: none !important;
  }
  .vrach-carousel-col {
    width: 30% !important;
    padding-right: 0;
  }
  .gl .slick-arrow {
    top: 300px;
  }
  .gl .carousel-doc {
    max-width: 66%;
  }
  .rec-main {
    flex-direction: column;
  }
  .service-col__a {
    font-size: 10px;
  }
  .rec-main-col3 {
    display: flex;
    margin: 0;
    justify-content: space-between;
    align-items: flex-end;
  }
  .rec-main-col3 input {
    width: 423px;
    margin-left: 10px;
  }
  .service-col__h2 {
    padding-bottom: 6px;
    margin-bottom: 17px;
  }
  .serv {
    width: 100%;
  }
  .service {
    flex-direction: column;
    align-items: flex-start;
  }
  .service-col {
    flex-basis: 25%;
    margin: 0;
  }
  .wrapper .doc-flex-img {
    width: 280px;
    height: auto;
    padding: 0 30px 30px 0;
  }
  .wrapper .doc-flex-img__img {
    width: 280px;
    height: auto;
  }
  .description__h2 {
    font-size: 14px;
  }
  .description .fl1-desc__h1 {
    font-size: 36px;
  }
  .vrach-carousel-col {
    width: 192px !important;
    margin-right: 30px;
  }
  .carousel-doc {
    width: calc(100% - 222px) !important;
  }
  .until-slider {
    width: 94%;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (max-width: 800px) {
  .template {
    flex-direction: column;
  }
  .template .temp-prew {
    margin: 0;
    width: 100%;
  }
  .template .temp-prew__p {
    font-weight: 500;
    font-size: 14px;
  }
  .template-desc__p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
  }
  .fl .vrach-carousel .slick-arrow {
    top: 300px;
  }
  .gg .slick-next {
    right: 0 !important;
  }
  .vrach-carousel .doc-unit .doccock {
    margin: 0 !important;
  }
  .ff__h3,
  .gg__h3 {
    display: block;
  }
  .ff .until-slider,
  .gg .until-slider {
    position: relative;
    margin-bottom: 0;
    padding-bottom: 18px;
    border-bottom: 1px solid #B2B2B2;
  }
  .ff .slick-arrow,
  .gg .slick-arrow {
    z-index: 1000;
    height: 40px;
    width: 40px;
    top: auto;
    bottom: -51px;
  }
  .ff .slick-arrow.slick-prev,
  .gg .slick-arrow.slick-prev {
    left: auto !important;
    right: 50px;
    background: url("../images/prev.png") center center no-repeat transparent;
    background-size: 100% !important;
  }
  .ff .slick-arrow.slick-prev:hover,
  .gg .slick-arrow.slick-prev:hover {
    background-size: 100% !important;
    background: url("../images/prev.png") center center no-repeat transparent;
  }
  .ff .slick-arrow.slick-next,
  .gg .slick-arrow.slick-next {
    background-size: 100% !important;
    background: url("../images/next-next.svg") center center no-repeat transparent;
  }
  .ff .slick-arrow.slick-next:hover,
  .gg .slick-arrow.slick-next:hover {
    background-size: 100% !important;
    background: url("../images/next.png") center center no-repeat transparent;
  }
  .ff .slick-arrow.slick-disabled,
  .gg .slick-arrow.slick-disabled {
    display: block !important;
  }
  .percent {
    flex-direction: column;
  }
  .percent .temp-prew {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
  }
  .percent .temp-prew__h2 {
    width: auto;
    font-size: 18px;
  }
  .percent .temp-prew .more {
    margin: 0;
    flex-basis: 110px;
  }
}
@media screen and (max-width: 770px) {
  .about-block .about-fl-img {
    padding-right: 0 !important;
    width: 100%;
    padding-bottom: 20px;
  }
  .dis {
    flex-basis: 33% !important;
  }
  .diseases > :nth-child(n + 7) {
    display: none;
  }
  .diseases > :nth-child(n + 5) {
    margin-top: 0px !important;
  }
  .doc-list .doc-unit {
    overflow: visible;
  }
  .doc-list .doc-unit:nth-child(n + 7) {
    display: none;
  }
  .doc-list {
    padding: 28px 0 45px;
    flex-wrap: wrap;
  }
  .description .fl1-desc__h1 {
    font-size: 24px;
  }
  .diseases > :not(:nth-child(5n)) > .dis-head,
  .diseases > :not(:nth-child(5n)) > .dis-down {
    border-right: 1px solid #C4C4C4 !important;
  }
  .diseases > :nth-child(3n) > .dis-head,
  .diseases > :nth-child(3n) > .dis-down {
    border-right: none !important;
  }
  .diseases > :nth-child(n + 4) {
    margin-top: 60px !important;
  }
}
@media screen and (max-width: 650px) {
  .fl .vrach-carousel .doc-unit .doccock {
    margin: 0 auto !important;
  }
}
@media screen and (max-width: 610px) {
  .doc-list .doc-unit:nth-child(n + 3) {
    margin-top: 32px !important;
  }
}
@media screen and (max-width: 600px) {
  .banner__record {
    width: 150px !important;
  }
  .main-head .info-block .logo img {
    height: 36px;
  }
  .main-head .nav-bar {
    padding: 0 11px;
    top: 79px;
  }
  .main-head .nav-bar__p {
    font-size: 32px;
    padding: 22px 0 20px !important;
  }
  .main-head .nav-bar__link {
    font-size: 24px;
  }
  .pricelist-block {
    padding-right: 11px !important;
  }
  .description .fl1-desc {
    align-items: flex-start;
  }
  .description .fl1-desc__back {
    font-size: 14px !important;
    width: 130px !important;
    margin-top: 8px;
  }
  .description .fl1-desc__back svg {
    width: 12px !important;
    height: 12px !important;
  }
  .pricelist-block .place .price-global .item-price__name {
    flex-basis: 70% !important;
  }
  .pricelist-block .place .price-global {
    flex-basis: auto !important;
  }
  .item-price__price {
    flex-basis: 1;
  }
  .place {
    flex-direction: column;
  }
  .place-name {
    padding-left: 11px !important;
  }
  .pricelist-block .place-name__h2 {
    font-size: 25px !important;
  }
  .pricelist-block .place .price-global__h3 {
    padding-top: 15px;
    font-size: 22px !important;
  }
  .link-bar .col_f {
    margin-bottom: 10px !important;
  }
  .col {
    flex-basis: 25% !important;
    box-sizing: border-box;
  }
  .col__p {
    padding-left: 0 !important;
  }
  .col__a {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .col__a:not(:last-child) {
    padding-right: 10px !important;
  }
  .diseases {
    padding-top: 10px;
  }
  .rec-main {
    align-items: center;
  }
  .rec-main-col3 {
    flex-direction: column;
    align-items: center;
  }
  .numb-foot .time__tel-link {
    font-size: 10px;
  }
  .main-section {
    padding: 0 10px;
  }
  .banner {
    width: 100%;
    margin-left: -10px;
    background: #B5CCE7 url("../images/girl2.png") no-repeat 100% center;
    background-position-x: 100% !important;
  }
  .banner__h1 {
    font-size: 26px !important;
  }
  .doc-block {
    flex-direction: column;
  }
  .doc-card-p {
    padding: 0 !important;
  }
  .doc-card {
    padding-bottom: 20px;
  }
  .link-bar {
    flex-wrap: wrap;
    border-bottom: 1px solid #E5E5E5;
    padding-bottom: 5px !important;
  }
  .link-bar .col {
    flex-basis: 50%;
    border-right: none !important;
  }
  .link-bar .col br {
    content: "";
  }
  .link-bar .col br:after {
    content: "\00a0";
  }
  .link-bar .col_f {
    flex-basis: 100% !important;
    border-bottom: 1px solid #E5E5E5;
    padding-bottom: 10px;
    margin-bottom: 30px;
  }
  .link-bar .col__a,
  .link-bar .col__p {
    padding: 0;
  }
  .link-bar .col__p {
    padding-bottom: 6px;
  }
  .link-bar .col__a {
    padding-bottom: 20px;
  }
  .main-head .time__tel-link,
  .main-head .time__p {
    display: none;
  }
  .main-head .time .phone {
    display: block;
  }
  .main-foot .info-foot {
    flex-direction: row-reverse;
  }
  .main-foot .info-foot .geo-foot {
    flex-direction: column;
    align-items: flex-start;
  }
  .main-foot .info-foot .geo-foot__link {
    margin: 0;
  }
  .main-foot .info-foot .geo-foot__link_adress {
    padding-bottom: 5px;
  }
  .main-foot .info-foot .geo-foot__link_adress svg {
    padding-left: 1.5px;
    padding-right: 12px;
  }
  .main-foot .info-foot .numb-foot {
    text-align: left;
  }
  .main-foot .info-foot .numb-foot .time__tel-link1 {
    padding-bottom: 5px;
  }
  .main-foot .info-foot .numb-foot a {
    display: block;
  }
  .main-foot .copyright {
    padding: 25px 0 27px;
  }
  .banner__laz {
    font-size: 10px !important;
  }
  .wrapper .doc-flex-img {
    width: 210px;
  }
  .wrapper .doc-flex-img__img {
    width: 100%;
  }
  .wrapper .doc-flex .merit-block__h3 {
    font-size: 14px;
  }
  .wrapper .doc-flex .merit-block__p {
    font-size: 12px;
  }
  .wrapper .doc-flex .merit-block {
    padding-right: 0px !important;
    padding-bottom: 50px;
  }
  .until-slider {
    width: calc(100% + 4px);
  }
  .until-slider .until-desc {
    padding: 10px 15px 0;
    height: auto;
  }
  .banner {
    background: #B5CCE7 url("../images/girl2.png") no-repeat 100% center;
    background-position-x: right;
  }
  .banner__h1 {
    font-size: 26px !important;
    line-height: 39px !important;
  }
  .banner__record {
    font-size: 14px;
    vertical-align: middle;
  }
  .banner .fl {
    flex-direction: column-reverse;
  }
  .banner .fl-doc__img {
    margin-left: 0 !important;
    margin-bottom: 10px;
  }
  .rec-main .lf {
    flex-direction: column;
  }
  .rec-main .lf .rec-main-col1 {
    margin: 0;
  }
  .rec-main .lf .rec-main-col1 {
    margin-bottom: 40px;
  }
  .rec-main .lf .rec-main-col2 {
    margin-left: 0;
  }
  .rec-main .lf_col input {
    margin-left: 0;
    width: 262px;
  }
  .rec-main .rec-main-col3__a {
    width: 262px;
  }
  .dis {
    flex-basis: 50% !important;
  }
  .diseases > :nth-child(n + 7) {
    display: none;
  }
  .diseases > :nth-child(n + 5) {
    margin-top: 0px !important;
  }
  .diseases > :not(:nth-child(5n)) > .dis-head,
  .diseases > :not(:nth-child(5n)) > .dis-down {
    border-right: none !important;
  }
  .diseases > :nth-child(2n + 1) > .dis-head,
  .diseases > :nth-child(2n + 1) > .dis-down {
    border-right: 1px solid #C4C4C4 !important;
  }
  .diseases > :nth-child(n + 4) {
    margin-top: 0px !important;
  }
  .diseases > :nth-child(n + 3) {
    margin-top: 60px !important;
  }
  .vrach-carousel-col {
    width: 40% !important;
    margin-right: 30px;
  }
  .gl .carousel-doc,
  .fl .carousel-doc {
    max-width: 192px;
  }
  .rec-head {
    align-items: flex-start;
    padding: 0 0 15px;
  }
}
@media screen and (max-width: 390px) {
  .fl .vrach-carousel .carousel-doc {
    max-width: 192px !important;
  }
  .wrapper .doc-flex-img {
    padding: 0 0 10px !important;
    width: 100% !important;
    float: none !important;
  }
  .doc-list .doc-unit .doccock {
    height: auto;
  }
  .gl .slick-arrow.slick-next,
  .fl .slick-arrow.slick-next {
    left: auto !important;
    right: 0px !important;
  }
  .gl .slick-arrow.slick-prev,
  .fl .slick-arrow.slick-prev {
    left: auto !important;
    right: 50px !important;
  }
  .doc-list .doc-unit .more {
    position: static;
  }
  .doc-list .doc-unit__desc {
    padding-bottom: 0;
  }
  .doc-list {
    justify-content: space-around !important;
  }
  .doc-list .doc-unit:nth-child(n + 3) {
    margin-top: 0 !important;
  }
  .doc-list .doc-unit:nth-child(n) {
    margin-top: 20px !important;
  }
  .main-foot .link-bar {
    justify-content: space-between;
  }
  .main-foot .link-bar .col {
    flex-basis: 20% !important;
  }
  .main-foot .link-bar .col_f {
    flex-basis: 100% !important;
  }
  .main-foot .link-bar .col__a {
    font-size: 10px;
  }
  .vrach-carousel .carousel-doc {
    width: auto !important;
  }
  .vrach-carousel-col {
    margin-right: 5px !important;
  }
  .gl .slick-arrow,
  .fl .slick-arrow {
    top: 388px !important;
  }
  .gl .slick-arrow.slick-next,
  .fl .slick-arrow.slick-next {
    left: 100px;
  }
  .gl .slick-arrow.slick-prev,
  .fl .slick-arrow.slick-prev {
    left: 40px;
  }
  .main-foot .link-bar .col__a {
    padding-bottom: 0 !important;
    padding-right: 0 !important;
  }
  .dis {
    flex-basis: 100% !important;
  }
  .dis-desc {
    width: auto !important;
  }
  .fl-doc__act,
  .fl-doc__name {
    color: white !important;
  }
  .diseases > :nth-child(n + 7) {
    display: none;
  }
  .diseases > :nth-child(n + 5) {
    margin-top: 0px !important;
  }
  .diseases > :not(:nth-child(5n)) > .dis-head,
  .diseases > :not(:nth-child(5n)) > .dis-down {
    border-right: none !important;
  }
  .diseases > :nth-child(2n + 1) > .dis-head,
  .diseases > :nth-child(2n + 1) > .dis-down {
    border-right: none;
  }
  .diseases > :nth-child(n + 4) {
    margin-top: 0px !important;
  }
  .diseases > :nth-child(n + 2) {
    margin-top: 20px !important;
  }
}
@media screen and (max-width: 350px) {
  .fl .vrach-carousel .slick-arrow {
    top: auto !important;
    bottom: -30px !important;
  }
  .fl1-desc__price {
    display: block;
    width: 130px;
  }
  .main-head .nav-bar__link {
    padding: 8px 0;
    font-size: 18px;
  }
}
