.pricelist-block{
    width: 100%;
    padding-right: 23px;
    box-sizing: border-box;
    .eyes{
        padding-top: 50px;
        .price-global{
            padding-top: 13px;
        }
    }
    
    .place{
        display: flex;
        justify-content: space-between;
        padding-bottom: 38px;
        &:not(:last-child){
            border-bottom: 1px solid #B2B2B2;
        }
        &-name{
            padding-left: 22px;
            &__h2{
                font-weight: 500;
                font-size: 24px;
            }
        }
        .price-global{
            flex-basis: 636px;
            &__h3, &__exp, &__h4{
                margin-left: 11px;
            }
            &__h3{
                font-weight: 500;
                font-size: 25px;
                letter-spacing: 0.01em;
                padding-bottom: 8px;
                &_m{
                    margin-top: 47px;
                }
            }
            &__exp{
                line-height: 12px;
                font-size: 10px;
                color: #328DD8;
               padding-bottom: 13px;
                &_g{
                    color: #21D3BC;
                    border-bottom: 1px solid #B2B2B2;
                    margin-bottom: 12px;
                }
            }
            &__h4{
                font-weight: 900;
                font-size: 12px;
                letter-spacing: 0.05em;
                padding: 14px 0;
                border-bottom: 1px solid #B2B2B2;
                margin-bottom: 12px;
            }
            .item-price{
                padding: 2px 13px 2px 11px;
                margin-bottom: 12px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-radius: 10px;
                &_p{
                    margin-top: 36px;
                }
                &__name{
                    flex-basis: 450px;
                }
                &:hover{
                    background: #D6E3F3;
                    cursor: pointer;
                }
            }

        }
    }
}