.index__h1{
    text-align: center;
    padding: 20px 0 20px;
    text-transform: uppercase;
    font-size: 20px;
}
.doc-block{
    position: relative;
}
.banner{
    max-width: 100%;
    background: #B5CCE7 url("../images/girl.png") no-repeat 100% center;
    background-size: auto 100%;
    color: white;
    padding-left: 21px;
    a{
        color: white;
    }
    &__h2{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        font-size: 18px;
        padding-top: 22px;
    }
    &__h1{
        font-family: 'PT Sans';
        font-style: normal;
        font-weight: bold;
        line-height: 75px;
        font-size: 55px;
        letter-spacing: 0.05em;
    } 
    &__laz{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: bold;
        line-height: normal;
        font-size: 18px;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        color: #272727;
        padding-top: 6px;
    }
    &__price{
        font-family: 'PT Sans';
        font-style: normal;
        font-weight: normal;
        line-height: normal;
        font-size: 20px;
        &-span{
            font-size: 13px;
        }
        padding-bottom: 20px;
    }
    &__record{
        display: flex;
        width: 192px;
        justify-content: space-between;
        align-items: center;
        background: rgba(75, 195, 233, 0.8);
        border: 3px solid #FFFFFF;
        box-sizing: border-box;
        box-shadow: 5px 10px 25px rgba(57, 162, 195, 0.6);
        border-radius: 20px;
        padding: 5px 7px 5px 15px;
        font-family: 'PT Sans';
        font-style: normal;
        font-weight: normal;
        line-height: normal;
        font-size: 20px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        &:hover{
            cursor: pointer;
            animation: upUp 0.3s linear;
            transform: translateY(-4px);

        }
    }
    .fl{
        display: flex;
        margin-top: 28px;
        &-times{
            display: flex;
            &__ti{
                padding: 8px 6px;
                background-color: white;
                font-family: 'PT Sans';
                font-style: normal;
                font-weight: bold;
                line-height: normal;
                font-size: 36px;
                letter-spacing: 0.09em;
                text-transform: uppercase;
                color: #272727;
                &:not(:last-child){
                    margin-right: 4px;
                }
            }
        }
        &-doc{
            display: flex;
            align-items: center;
            &__img{
                margin-left: 39px;
                margin-right: 5px;
                display: block;
                width: 56px;
                border-radius: 56px;
                border: 2px solid #E8E8E8;
            }
            &__act{
                font-family: 'PT Sans';
                font-weight: bold;
                font-size: 10px;
                letter-spacing: 0.04em;
                color: #272727;
            }
            &__name{
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                font-size: 18px;
                letter-spacing: 0.01em;
                padding-top: 2px;
                color: #181818;
            }
        }
    }
    &__p{
        font-family: 'PT Sans';
        padding-top: 7px;
        font-style: normal;
        font-weight: normal;
        line-height: normal;
        font-size: 10px;
        letter-spacing: 0.03em;
        text-transform: uppercase;
        color: #272727;
        padding-bottom: 18px;
    }
}
.diseases{
    display: flex;
    flex-wrap: wrap;
    padding: 73px 0 29px;
    box-sizing: border-box;
    max-width: 1136px;
    &> :not(:nth-child(5n)) > .dis-head, > :not(:nth-child(5n)) > .dis-down{
        border-right: 1px solid #C4C4C4;
    }
    & > :nth-child(n + 6){
        margin-top: 39px;
    }
    .dis{
        flex-basis: 20%;
        &-down{
            padding-bottom: 10px;
        }
        &:hover{
            background-color: #D6ECFF;
        }
        &-head{
            a{
                width: 100%;
                box-sizing: border-box;
                display: flex;
                justify-content: space-around;
                align-items: flex-end;
            }
            display: flex;
            align-items: flex-end;
            height: 67px;
            box-sizing: border-box;
            //padding: 28px 30px 18px 21px;
            padding: 0 0 18px;
        }
        &-img{
            &__img{
                object-fit: cover;
            }
        }
        &-desc{
            width: 181px;
            margin: 0 auto;
            &__desc{
                font-family: 'PT Sans';
                font-style: normal;
                font-weight: normal;
                line-height: normal;
                font-size: 12px;
                color: black;
                padding: 16px 0 11px;

            }
            &__more{
                width: 141px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border: 1px solid #328DD8;
                box-sizing: border-box;
                border-radius: 50px;
                padding: 6px 31px;
                font-family: 'Roboto';
                font-size: 12px;
                &:hover{
                    animation: upUp 0.4s ease-in-out;
                    transform: translate(0, -4px);
                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                }
                &:active{
                    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
                }
            }
        }
    }
}
.doctors{
    padding-bottom: 44px;
    &__h1{
        padding-bottom: 23px;
        font-family: 'Roboto';
        font-weight: 500;
        font-size: 18px;
        letter-spacing: 0.03em;
    }
    .doc-block{
        display: flex;
        justify-content: space-between;
        .doc-card{
            flex-basis: 25%;
            &-p{
                padding-right: 30px;
            }
            &__p{
                font-family: 'PT Sans';
                font-size: 12px;
                letter-spacing: 0.04em;
                color: #272727;
            }
            .doc-info{
                margin-bottom: 8px;
                &__img{
                    float: left;
                    width: 82px;
                    border-radius: 45px;
                    border: 4px solid #E8E8E8;
                    margin-right: 11px;
                }
                &:after{ 
                    content: " "; 
                    display: block; 
                    height: 0; 
                    clear: both; 
                    visibility: hidden; 
                    font-size: 0; 
                }
                &__h2{
                    font-family: 'Roboto';
                    font-size: 18px;
                    color: #328DD8;
                    padding-bottom: 9px;
                }
                &__p{
                    font-family: 'PT Sans';
                    font-weight: bold;
                    font-size: 9px;
                    letter-spacing: 0.04em;
                    color: #272727;
                }
            }
        }
    }
}