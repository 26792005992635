.about-block{
    padding-top: 27px;
    .about-fl{
        display: flex;
        &-img{
            width: 649px;
            height: 451px;
            padding-right: 30px;
            flex-shrink: 2;
            &__img{
                object-fit: cover;
            }      
        }
        &-desc{
            &__h2{
                font-family: 'PT Sans';
                line-height: 28px;
                font-size: 28px;
                letter-spacing: 0.09em;
                color: #328DD8;
                padding-bottom: 28px;
                &_bold{
                    font-weight: bold;
                }
            }
            &__p{
                line-height: 22px;
                font-size: 14px;
                padding-bottom: 22px;
            }
        }
    }
}
.service-col1{
    flex-basis: 20% !important;
    margin: 0 !important;
    padding: 0 !important;
}
.serv{
    display: flex;
}
.service{
    display: flex;
    &-col{
        flex-basis: 25%;
        
            padding-top: 10px;
            margin-left: 30px;
        
        &__h2{
            padding-bottom: 16px;
            font-weight: 500;
            font-size: 25px;
            letter-spacing: 0.07em;
            border-bottom: 1px solid #B2B2B2;
        }
        &__a{
            display: block;
            text-transform: uppercase;
            font-family: 'PT Sans';
            font-weight: bold;
            font-size: 14px;
            letter-spacing: 0.05em;
            text-decoration-line: underline;
            padding-right: 10px;
            color: #328DD8;
            &:not(:last-child){
                padding-bottom: 15px;
            }
        }
        &__p{
            padding-top: 19px;
            font-family: 'PT Sans';
            line-height: 16px;
            font-size: 12px;
        }
    }   
}
.rec{
    &-head{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 22px 23px;
        margin-bottom: 27px;
        border-bottom: 2px solid #B5CCE7;
        &__h2{
            font-weight: 500;
            font-size: 25px;
            letter-spacing: 0.02em;
        }
        &__when{
            font-family: 'PT Sans';
            font-size: 14px;
        }
    }
    &-main{
        display: flex;
        padding-bottom: 55px;
        &-col{
            
            label{
                display: block;
                font-family: 'PT Sans';
                font-size: 14px;
                letter-spacing: 0.04em;
                padding-left: 17px;
                padding-bottom: 12px;
            }
            
            
        }
        &-col1{
            margin-left: 10px;
            height: 34px;
            
        }
        &-col2{
            margin-left: 12px;
            .select_mate {
                min-width: 145px !important;
                width: 145px !important;
            }
        }
        &-col3{
            margin-left: 45px;
            margin-top: 10px;
            input{
                outline: none;
                border: 1px solid #328DD8;
                box-sizing: border-box;
                border-radius: 21.5px;
                width: 457px;
                font-family: 'PT Sans';
                font-size: 14px;
                letter-spacing: 0.03em;
                text-transform: uppercase;
                padding: 11px 0 10px 15px;
                margin-top: 21px;
            }
            
            &__a{
                display: flex;
                width: 192px;
                justify-content: space-between;
                align-items: center;
                background-color: #328DD8;
                box-sizing: border-box;
                border-radius: 21.5px;
                padding: 10px 17px;
                font-family: 'PT Sans';
                font-size: 14px;
                margin-top: 21px;
                color: white;
                letter-spacing: 0.03em;
                text-transform: uppercase;
                &:hover{
                    cursor: pointer;
                }
            }
        }
    }
    .icon_select_mate {
        position: absolute;
        top: 10px;
        right: 15px;
        font-size: 16px;
        height: 20px;
        transition: all 275ms;
        &:hover{
        cursor: pointer;
        }
    }
  
    .select_mate {
        position: relative;
        float: left;
        min-width: 258px;
        width: 258px;
        min-height: 37px;
        font-family: 'PT Sans';
        color: #000;
        font-size: 14px;
        letter-spacing: 0.02em;
        font-weight: 300;
        background-color: #fff;
        border: 2px solid #328DD8;
        border-radius: 21.5px;
        transition: all 375ms ease-in-out;
        select {
        position: absolute;
        overflow: hidden;
        height: 0px;
        opacity: 0;  
        z-index: -1;
        }
    }
  
  .cont_list_select_mate {
    position: relative;
    float: left;
    width: 100%;
}
  
  .cont_select_int {
    position: absolute;
    left: 0px;
    top: 3px;
    z-index: 999;
    overflow: hidden;
    height: 0px;
    width: 100%;
    background-color: #fff;
    padding: 0px;
    margin-bottom: 0px;
    margin-top: 0px;
    border-radius: 5px;
    transition: all 375ms ease-in-out;
        li {
            position: relative;
            float: left;
            width: 258px;
            border-bottom:1px solid #E0E0E0;
            background-color: #F5F5F5;
            list-style-type: none;
            padding: 10px 13px;
            margin: 0px;  
            transition: all 275ms ease-in-out;
            display: block;
            cursor:pointer;
            &:last-child {
                border-radius: 3px;
                border-bottom:0px; 
            }
            &:hover {
                background-color: #EEEEEE;
            } 
        }
        .active {
            background-color: #EEEEEE;
        }
  
    }
    .selecionado_opcion {
        padding: 9px 0px 10px 15px;
        width: 100%;
        display: block;
        margin: 0px;
        cursor: pointer;
    } 
  
  
}





