.main-foot{
    max-width: 100%;
    .info-foot{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 26px 0;
        border-top: 1px solid #E5E5E5;
        border-bottom: 1px solid #E5E5E5;
        &-links a{
            font-family: 'Roboto';
            font-style: normal;
            font-weight: normal;
            line-height: normal;
            font-size: 12px;
            letter-spacing: 0.03em;
            text-decoration-line: underline;
            color: #181818;
            &:not(:last-child){
                margin-right: 14px;
            }
            &:hover{
                color: #328DD8;
            }
            &:active{
                font-weight: 500;
                color: #328DD8;
            }
        }
        .geo-foot{
            display: flex;
            justify-content: space-between;
            align-items: center;
            svg{
                padding-right: 10px;
                path{
                    fill: #328DD8;
                }
                circle{
                    stroke: #328DD8;
                }
            }
            &__link{
                font-weight: 300;
                font-size: 12px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                &_adress{
                    margin-right: 30px;
                    text-decoration-line: underline;
                }           
            }
        }
        .numb-foot{
            font-family: 'Roboto';
            font-style: normal;
            font-weight: bold;
            line-height: normal;
            font-size: 14px;
            text-align: right;
            color: #000000;
        }
    }
    .link-bar{
        display: flex;
        padding-top: 25px;
        padding-bottom: 27px;
        p, a{
            display: block;
            padding: 12px 0 12px 20px;
        }
        .col{
            flex-basis: 20%;
            &:not(:last-child){
                border-right: 1px solid #B2B2B2;
            }
            &__a{
                font-family: 'Roboto';
                font-style: normal;
                font-weight: bold;
                line-height: 17px;
                font-size: 12px;
                letter-spacing: 0.01em;
                text-transform: uppercase;
                color: #328DD8;

                &:hover{
                    text-decoration: underline;
                }
                &:active{
                    color: #1373C2;
                    font-weight: 900;
                    text-decoration: none;
                }
            }
            &__p{
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                font-size: 12px;
                letter-spacing: 0.03em;
                color: #181818;
            }
        }
    }
    .copyright{
        display: flex;
        justify-content: space-between;
        padding: 0 20px 27px;
        &__p{
            display: block;        
            font-family: 'Roboto';
            font-style: normal;
            font-weight: normal;
            line-height: normal;
            font-size: 9px;
        }
    }
}