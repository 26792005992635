.wrapper{
    padding-top: 36px;
    display: flex;
    justify-content: space-between;
    .doc-flex{
        &-img{
            width: 420px;
            height: 492px;
            float: left;
            padding: 0 47px 57px 18px;
            &__img{
            object-fit: cover;
            }
        }
        &:after{ 
            content: " "; 
            display: block; 
            height: 0; 
            clear: both; 
            visibility: hidden; 
            font-size: 0; 
        }
        .merit-block{
            padding-right: 23px;
            &__h3{
                font-family: 'PT Sans';
                font-style: normal;
                font-weight: bold;
                line-height: normal;
                font-size: 18px;
                letter-spacing: 0.02em;
                padding-bottom: 24px;
            }
            &__p{
                &:not(:last-child){
                    padding-bottom: 17px;
                }
                font-weight: 500;
                line-height: 17px;
                font-size: 12px;
            }
        }
    }
    
}
.ff{
    &__h3{
        display: none;
        padding-top: 23px;
        font-weight: 500;
        font-size: 16px;
        line-height: normal;
        letter-spacing: 0.02em;
        padding-bottom: 40px;   
    }
    .slick-arrow{
        position: absolute;
        height: 100%;
        width: 20%;
        padding: 0;
        top: 0px;
        font-size: 0;
        z-index: 90;
        outline: none;
        cursor: pointer;
        border: none;
        background: none;
        &.slick-prev{
            left: 0px;
            background: url(../images/prev.png) center 120px no-repeat;
            &:hover{
                background: url(../images/prev.png) center 120px no-repeat, linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 100%);
            }
        }
        &.slick-next{
            right: 0px;
            background: url(../images/next.png) center 120px no-repeat;
            &:hover{
                background: url(../images/next.png) center 120px no-repeat, linear-gradient(270deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0) 100%);
            }
        }
        .slick-disabled {
            display: none !important;
        }
    }
}

.percent{
    display: flex;
    padding-right: 11px;
    width: 100%;
    margin-bottom: 72px;
    justify-content: space-between;
    .temp-prew{
        justify-content: space-between;
    }
    .temp-prew__h2{
        width: 203px;
        font-weight: 500;
        line-height: normal;
        font-size: 25px;
        font-style: normal;
        letter-spacing: 0.02em;
        text-transform: uppercase;
    }
    .more{
        img{
            width: 14px;
            height: 14px;
        }
        padding: 3px 4px 2px 6px;
        align-items: center;
    }
    .cont-calen{
        overflow-y: auto;
    }
    .calen{
        width: 655px;
        border-collapse: collapse;
        border: 2px solid white;
        th{
            text-align: left;
            //display: block;
            padding : 4px 0px 7px 6px;
            width: 92px;
            font-family: 'PT Sans';
            font-weight: bold;
            font-size: 10px;
            letter-spacing: 0.02em;
        }
        .calen_active{
            background-color: #328DD8;
            .calen__p1, .calen__p2{
                color: white;
            }
        }
        td, th{
            border: 1px solid #C4C4C4;
        }
        td{
            &:hover{
                background-color: #B5CCE7;
                cursor: pointer;
                .calen__p1{
                    color: white;
                }
                .calen__p2{
                    color: #181818;
                }
            }
            vertical-align: top;
        }
        &__p1{
            font-family: 'PT Sans';
            font-weight: bold;
            font-size: 26px;
            letter-spacing: 0.02em;
            color: #C4C4C4;
            padding: 3px 0 1px 3px;
        }
        &__p2{
            font-family: 'PT Sans';
            font-size: 10px;
            color: #C4C4C4;
            padding-left: 4px;
            padding-bottom: 5px;
        }
        &_sv{
            .calen__p1{
                color: #181818;
            }
        }
    }
}