@keyframes upUp {
    from {transform: translate(0, 0);}
    to {transform: translate(0, -4px);}
}
*{
    padding: 0;
    margin: 0;
}
.hamburger{
    display: none;
}
.lf{
    display: flex;
    &_col{
      flex-direction: column;
    }
  }
a, p, h1, h2, h3, h4{
    text-decoration: none;
    color: black;
    font-family: 'Roboto', sans-serif; 
}

.main-nav{
    padding-bottom: 12px;
    padding-top: 15px;
    &-p{
        font-family: 'Roboto';
        font-size: 10px;
        letter-spacing: 0.02em;
        &__a{
            text-decoration: underline;
            margin-right: 2px;
        }
    }
}
.description{
    padding-bottom: 23px;
    border-bottom: 2px solid #328DD8;
    .fl1-desc{
        display: flex;
        justify-content: space-between;
        align-items: center;
        &__back{
            font-family: 'PT Sans';
            font-size: 28px;
            color: #C4C4C4;
            display: flex;
            width: 179px;
            justify-content: space-between;
            align-items: center;
        }
        &__h1{   
            font-family: 'PT Sans';
            font-size: 55px;
            letter-spacing: 0.01em;
            color: #181818;
            span{
                font-weight: 900;
            }
        }
    }
    &__h2{
        font-family: 'PT Sans';
        font-size: 18px;
        letter-spacing: 0.06em;
        &_gray{
            font-style: normal;
            font-weight: normal;
            line-height: normal;
            font-size: 18px;
            letter-spacing: 0.09em;
            color: #C4C4C4;
            text-transform: uppercase;

        }
    }
}
aside{
    flex-basis: 225px;
    .comment-block{
        width: 192px;
        margin: 0 auto 50px;
        &__h2{
            font-family: 'Roboto';
            padding: 16px 0 14px;
            border-bottom: 1px solid #B2B2B2;
            font-weight: 500;
            font-size: 25px;
            letter-spacing: 0.02em;
        }
        &__p1{
            padding: 19px 0 14px;
            font-weight: 500;
            line-height: 15px;
            font-size: 12px;
            color: #328DD8;
        }
        &__p2{
            font-family: 'PT Sans';
            line-height: 16px;
            font-size: 12px;
            padding-bottom: 2px;
        }
        &__data{
            font-family: 'PT Sans';
            font-weight: bold;
            line-height: 14px;
            font-size: 10px;
            padding-bottom: 13px;
        }
        

    }
    .sale-block{
        position: relative;
        width: 100%;
        background: #B5CCE7;
        padding: 33px 26px 36px 17px;
        box-sizing: border-box;
        &__p2{
            display: none;
            //display: block;
            font-family: 'PT Sans';
            font-weight: bold;
            font-size: 10px;
            letter-spacing: 0.03em;
            text-transform: uppercase;
            padding: 23px 0 18px;
        }
        .fl-times{
            display: none;
            //display: flex;
            &__ti{
                &:not(:last-child){
                    margin-right: 3px;
                }
                width: 25px;
                height: 35px;
                display: flex;
                justify-content: space-between;
                text-align: center;
                align-items: center;
                background: linear-gradient(180deg, #FFFEFE 0%, #EBEBEB 100%);
                font-family: 'PT Sans';
                font-weight: bold;
                font-size: 28px;
                letter-spacing: 0.09em;
                color: #272727;
                p{
                    display: block;
                    margin: 0 auto;
                }
            }
        }
        &__h2{
            font-weight: 500;
            line-height: 29px;
            font-size: 26px;
            letter-spacing: 0.02em;
            text-transform: uppercase;
            color: #FFFFFF;
            padding-bottom: 13px;
        }
        &__p1{
            padding-bottom: 12px;
            font-weight: 500;
            line-height: 24px;
            font-size: 20px;
            letter-spacing: 0.02em;
            text-transform: uppercase;
        }
        
        &__span{
            font-family: 'PT Sans';
            font-size: 18px;
            letter-spacing: 0.11em;
            position: absolute;
            top: 218px;
            left: 93px;
        }
        &__img{
            margin-left: 11px;
        }
        &__arr{
            position: absolute;
            top: 159px;
            left: 162px;
        }

    }
}
.more{
    &:hover{
        animation: upUp 0.2s ease-in-out;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        transform: translateY(-4px); 
    }
    &:active{
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
    p, img{
        display: block;
    }
    p{
        font-family: 'PT Sans';
        font-weight: bold;
        font-size: 10px;
        line-height: 10px;
        vertical-align: middle;

        letter-spacing: 0.02em;
        color: #FFFFFF;
    }
    font-family: 'PT Sans';
    font-weight: bold;
    font-size: 10px;
    line-height: normal;
    vertical-align: middle;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    display: flex;
    justify-content: space-between;
    vertical-align: middle;
    align-items: center;
    padding: 6px 6px;
    background: #328DD8;
    border-radius: 20px;
    margin-top: 10px;
    width: 89px;
    &_v{
        width: 120px;
    }
}
.vrach-carousel{
    margin-top: 49px;
    padding-bottom: 34px;
    position: relative;
    .carousel-doc{
        max-width: 80%;
    }
    .blue-blur{
        content: '';
        width:207px;
        height: 412px;
        background: linear-gradient(270deg, #FFFFFF 41.44%, rgba(255, 255, 255, 0) 100%);
        position: absolute;
        right: 0;
        z-index: 10;
    }
    display: flex;
    .doc-unit{
        
        width: 189px;
        .doccock{
            margin: 0 auto;
            width: 189px;
            position: relative;
        }
        &-img{
            width: 100%;
            height: 193px;
            //box-sizing: border-box;
            &__img{
                object-fit: cover;
                border: 1px solid #C4C4C4;

            }
        }
        &__pos{
            padding: 14px 0 8px;
            font-family: 'PT Sans';
            font-weight: bold;
            font-size: 12px;
            letter-spacing: 0.04em;
            text-transform: uppercase;
            color: #272727;
        }
        &__name{
            padding-bottom: 4px;
            font-weight: 500;
            font-size: 18px;
            color: #328DD8;
        }
        &__desc{
            font-family: 'PT Sans';
            line-height: 16px;
            font-size: 12px;
        }
        .more{
            position: static;
            margin-top: 11px;
        }
    }
    &-col{
        width: 20%;
        //flex-shrink: 2;
        padding-right: 30px;
        box-sizing: border-box;
        &__h2{
            padding-bottom: 16px;
            font-weight: 500;
            font-size: 25px;
            letter-spacing: 0.07em;
            border-bottom: 1px solid #B2B2B2;
        }
        
        &__p{
            padding-top: 19px;
            font-family: 'PT Sans';
            line-height: 16px;
            font-size: 12px;
        }
    }
    .slick-arrow{
		position: absolute;
		width: 44px;
		height: 44px;
		top: 280px;
		padding: 0;
		font-size: 0;
		z-index: 9;
		outline: none;
		cursor: pointer;
		opacity: .8;
		border: none;
		background: none;
		&:hover{
            opacity: 1;
        }
		&.slick-prev{
            left: -130px;
            background: url(../images/arrow-left.svg) 0 0 no-repeat;
            background-size: 100%;
        }
		&.slick-next{
            left: -73px;
			background: url(../images/arrow-right.svg) 0 0 no-repeat;
            background-size: 100%;
        }
    }
}
.slick-disabled {
    display: none !important;
}
.until-slider{
    margin-bottom: 58px;
    
    .until{
      &-head{
        margin-bottom: 11px;
        border-right: 1px solid #C4C4C4;
        display: flex;
        &-p{
          flex-basis: 50%;
          &__p{
            padding-bottom: 3px;
            padding-left: 16px;
            font-family: 'PT Sans';
            font-size: 16px;
            letter-spacing: 0.04em;
          }
        }
      }
      &-img{
        display: flex;
        &1{
          flex-basis: 50%;
          overflow: hidden;
          &__img{
            width: 100%;
            object-fit: cover;
          }
        }
      }
      &-desc{
        border-right: 1px solid #C4C4C4;
        height: 243px;
        padding: 17px 30px 0px 16px;
        box-sizing: border-box;
        &__p{
          font-family: 'PT Sans';
          font-size: 12px;
          &:not(:last-child){
            padding-bottom: 15px;
          }
        }
      }
    }
}