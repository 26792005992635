.doc-list{
    padding: 28px 11px 47px 20px;
    display: flex;
    flex-wrap: wrap;
    & > :nth-child(n + 6){
        margin-top: 49px;
    }
    .doc-unit{
        flex-basis: 20%;
        
        overflow: hidden;
        .doccock{
            width: 189px;
            margin: 0 auto;
            height: 386px;
            position: relative;
            padding-bottom: 4px;
        }
        &-img{
            width: 100%;
            height: 193px;
            border: 1px solid #C4C4C4;
            &__img{
                object-fit: cover;
                
            }
        }
        &__pos{
            padding: 14px 0 8px;
            font-family: 'PT Sans';
            font-weight: bold;
            font-size: 12px;
            letter-spacing: 0.04em;
            text-transform: uppercase;
            color: #272727;
        }
        &__name{
            padding-bottom: 4px;
            font-weight: 500;
            font-size: 18px;
            color: #328DD8;
        }
        &__desc{
            padding-bottom: 16px;
            font-family: 'PT Sans';
            line-height: 16px;
            font-size: 12px;
        }
        .more{
            position: absolute;
            bottom: 4px;
        }
    }
}